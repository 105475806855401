import React from "react";
import MDBox from "components/MDBox";
import airlinrlogo from "assets/user-dash/airplane.png";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import plane from "assets/img/plane.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const FlightBooking = ({ details }) => {
  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <MDBox py={3}>
      <div className="user_detail_section">
        <MDTypography variant="p" className="p-title">
          <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "}
          {details?.departure_city}-to-{details?.destination_city}
        </MDTypography>
      </div>
      <Card className="mt-4">
        {
          details?.children?.length > 0 ?
            <div
              className="airline_header row"
            >
              <div className="airline_data col-md-3">
                <img src={airlinrlogo} alt="airline logo" />
                <div className="airline_title_section">
                  <MDTypography
                    variant="h6"
                    color="white"
                    className="air_line_title"
                  >
                    {details?.airline}
                  </MDTypography>
                  <div>
                    {details?.one_way ? (
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >
                        One Way
                      </MDTypography>
                    ) : (
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >
                        Round Trip
                      </MDTypography>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <MDTypography
                  variant="h6"
                  className="airline_Text"
                >
                  ${details?.price}
                </MDTypography>
              </div>

              <div className="col-md-4">
                <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                  Depature
                </MDTypography>
                <div
                  className="ticket_detail_heading"
                >
                  <MDTypography variant="h6">
                    {details?.departure_city?.split(",")[0]}
                    -to-
                    {details?.destination_city?.split(",")[0]}
                  </MDTypography>
                  <div className="ticket_details">

                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      {details?.flight_number}
                    </MDTypography>
                    <span>|</span>
                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      1 Adult
                    </MDTypography>
                    <span>|</span>
                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      {details?.flight_class}
                    </MDTypography>
                  </div>
                  <MDTypography variant="h6" className="ticket_title derature_date_time">
                    {details?.departure_date} {details?.departure_time}
                  </MDTypography>
                  <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                    {details?.return_date && details?.Arrives_time
                      ? `${details?.return_date} ${details?.Arrives_time}`
                      : details?.return_date}
                  </MDTypography>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  {details?.children && details?.children.length > 0 && (
                    <div>
                      {details?.children?.map((childFlight, index) => (
                        <div key={index} className="child_flight_info">
                          <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                            Return
                          </MDTypography>
                          <div
                            className="ticket_detail_heading"
                          >
                            <MDTypography variant="h6">
                              {childFlight?.departure_city?.split(",")[0]}
                              -to-
                              {childFlight?.destination_city?.split(",")[0]}
                            </MDTypography>
                            <div className="ticket_details">

                              <MDTypography
                                variant="p"
                                className="ticket_title"
                              >
                                {childFlight?.flight_number}
                              </MDTypography>
                              <span>|</span>
                              <MDTypography
                                variant="p"
                                className="ticket_title"
                              >
                                1 Adult
                              </MDTypography>
                              <span>|</span>
                              <MDTypography
                                variant="p"
                                className="ticket_title"
                              >
                                {childFlight?.flight_class}
                              </MDTypography>
                            </div>
                            <MDTypography variant="h6" className="ticket_title derature_date_time">
                              {childFlight?.departure_date} {childFlight?.departure_time}
                            </MDTypography>
                            <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                              {childFlight?.return_date && childFlight?.Arrives_time
                                ? `${childFlight?.return_date} ${childFlight?.Arrives_time}`
                                : childFlight?.return_date}
                            </MDTypography>
                          </div>
                        </div>

                      ))}
                    </div>
                  )}
                </div>

              </div>
            </div>
            :
            <div
              className="airline_header row"
            >
              <div className="airline_data col-md-3">
                <img src={airlinrlogo} alt="airline logo" />
                <div className="airline_title_section">
                  <MDTypography
                    variant="h6"
                    color="white"
                    className="air_line_title"
                  >
                    {details?.airline}
                  </MDTypography>
                  <div>
                    {details?.one_way ? (
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >
                        One Way
                      </MDTypography>
                    ) : (
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >
                        Round Trip
                      </MDTypography>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <MDTypography
                  variant="h6"
                  className="airline_Text"
                >
                  ${details?.price}
                </MDTypography>
              </div>

              <div className="col-md-6">
                <div
                  className="ticket_detail_heading"
                  style={{ marginTop: "20px" }}
                >
                  <MDTypography variant="h6">
                    {details?.departure_city?.split(",")[0]}
                    -to-
                    {details?.destination_city?.split(",")[0]}
                  </MDTypography>
                  <div className="ticket_details">

                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      {details?.flight_number}
                    </MDTypography>
                    <span>|</span>
                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      1 Adult
                    </MDTypography>
                    <span>|</span>
                    <MDTypography
                      variant="p"
                      className="ticket_title"
                    >
                      {details?.flight_class}
                    </MDTypography>
                  </div>
                  <MDTypography variant="h6" className="ticket_title derature_date_time">
                    {details?.departure_date} {details?.departure_time}
                  </MDTypography>
                  <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                    {details?.return_date && details?.Arrives_time
                      ? `${details?.return_date} ${details?.Arrives_time}`
                      : details?.return_date}
                  </MDTypography>
                </div>
              </div>
            </div>
        }
      </Card>
    </MDBox>
  );
};

export default FlightBooking;
