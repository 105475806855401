import MDTypography from "components/MDTypography";
import React from "react";
import HotelImg from "assets/user-dash/hotelimg.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { updateSubstituteStatus } from "utils/api";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { updateHotelStatus } from "utils/api";

const HotelBooking = ({
  booking,
  substitutes,
  fetchAllSubstitute,
  passBooking,
}) => {
  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");

  const REASON_CHOICES = [
    ["INCOMPLETE_RECORDS", "Incomplete Records"],
    ["TECHNICAL_ISSUES", "Technical Issues"],
    ["POLICY_RESTRICTIONS", "Policy Restrictions"],
    ["HUMAN_ERROR", "Human Error"],
    ["NOT_RELEVANT", "Not Relevant"],
    ["OTHER", "Other"],
  ];

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
    } else {
      updateHotelStatus(show, {
        status: status,
      })
        .then((res) => {
          console.log("status response", res);
          if (res.status == 200) {
            toast.success(`Status ${status?.toLowerCase()}ed successfully`, {
              theme: "colored",
            });
            fetchAllSubstitute();
          } else {
            toast.error(`Failed to ${status?.toLowerCase()} status`, {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSaving = (item_price, ticket_price) => {
    // const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (ticketPrice - item_price).toFixed(2);
    return parseFloat(savings);
  };
  return (
    <>
      <MDBox py={3}>
        <MDTypography variant="p" className="p-title">
          Price Monitor
        </MDTypography>
        <div className="container-fluid mb-4 mainpricemonitor">
          <div className="row align-items-center">
            <div className="col-md-3">
              <p className="Current-Plane">Your Current Hotel Booking</p>
            </div>
            <div className="col-md-8 dotted-gradient-border"></div>
            {booking.length > 0 ? (
              booking.map((item, index) => (
                <div key={index} className="currentTicket">
                  <div className="col-md-4 airlinetick">
                    <div className="">
                      <img src={HotelImg} alt="" className="img-fluid" />
                    </div>
                    <div className="ticktname">
                      <MDTypography
                        variant="h6"
                        color="white"
                        className="airline_heading"
                      >
                        {item?.hotel_name}
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        color="white"
                        className="ticket_title"
                      >
                        Check in: {item?.check_in_date}
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        color="white"
                        className="ticket_title"
                      >
                        Check out: {item?.check_out_date}
                      </MDTypography>

                      {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <MDTypography
                      variant="h5"
                      color="white"
                      className="flight_details"
                    >
                      {item?.guest_name}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="ticket_title"
                    >
                      Booking reference: {item?.booking_reference}
                    </MDTypography>
                  </div>
                  <div className="col-md-3">
                    <MDTypography
                      variant="h5"
                      color="white"
                      className="flight_details"
                    >
                      {item?.rental_price}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="ticket_title"
                    >
                      {item?.room_type}
                    </MDTypography>
                  </div>
                </div>
              ))
            ) : (
              <p>No flight available</p>
            )}
          </div>
        </div>
      </MDBox>
      {substitutes.length > 0 ? (
        <>
          <MDTypography variant="p" className="p-title">
            Substitute Tickets
          </MDTypography>
          {substitutes.map((hotel, index) => {
            return (
              <div key={index} className="container-fluid p-2 mb-3 subtiktmain">
                <div className="airline_header substitutes_card_flight row">
                  <div className=" hotel_name_title col-md-4">
                    <MDTypography variant="h6" className="hotel_name">
                      {hotel.hotel_name}
                    </MDTypography>
                    <div className="ticket_details">
                      <MDTypography variant="p" className="ticket_title">
                        Check in: {hotel.check_in_date}
                      </MDTypography>
                    </div>
                    <div className="ticket_details">
                      <MDTypography variant="p" className="ticket_title">
                        Check out: {hotel.check_out_date}
                      </MDTypography>
                    </div>

                  </div>
                  <div className="flight_price col-md-2">
                    <MDTypography variant="p" className="fstptag">
                      {hotel.price_currency}&nbsp;
                      {hotel.price_total}
                    </MDTypography>
                    <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                      You Can Save{" "}
                      <span
                        style={{
                          color: "#61a108",
                          fontWeight: "bold",
                          fontSize: "13px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        ${hotel?.saved_amount}
                        {/* {hotel?.saved_amount?.toFixed(2)} */}
                        {/* $ {getSaving(hotel?.price?.total, item.rental_price)} */}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-2">
                    <MDTypography variant="p" className="ticket_title">
                      {hotel.adults} Adult
                    </MDTypography>
                    <span className="mx-1">|</span>
                    <MDTypography
                      variant="p"
                      className="ticket_title text-capitalize"
                    >
                      {hotel.room_type_estimated.bedType}
                    </MDTypography>
                  </div>

                  {passBooking == "pass_booking" ? (
                    <div className="col-md-2 d-flex justify-content-end  airlinetkt">
                      <div className="col-md-12 text-end">
                        <div className="ticket_detail_butns">
                          <MDButton
                            className={`past_status_butn ${hotel?.status == "ACCEPT" && "accepted_butn"
                              }
                                ${hotel?.status == "REJECT" && "rejected_butn"}
                                ${hotel?.status == "ELIMINATE" &&
                              "eliminated_butn"
                              }
                                ${hotel?.status == "PENDING" && "accept_butn"}`}
                          >
                            {hotel?.status == "ACCEPT" && "ACCEPTED"}
                            {hotel?.status == "REJECT" && "REJECTED"}
                            {hotel?.status == "ELIMINATE" && "ELIMINATED"}
                            {hotel?.status == "PENDING" && "PENDING"}
                          </MDButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {hotel?.status == "PENDING" ? (
                        <div className="col-md-4 d-flex airlinetkt">
                          <div className="col-md-12">
                            <div className="ticket_detail_butns">
                              <MDButton
                                className="accept_butn"
                                onClick={() => handleStatus(hotel.id, "ACCEPT")}
                                disabled={
                                  ticketStatuses[hotel.id] ||
                                  hotel.status !== "PENDING"
                                }
                              >
                                ACCEPT
                              </MDButton>
                              <MDButton
                                className={`reject_butn
                                            `}
                                onClick={() => handleStatus(hotel.id, "REJECT")}
                                disabled={
                                  ticketStatuses[hotel.id] ||
                                  hotel.status !== "PENDING"
                                }
                              >
                                REJECT
                              </MDButton>
                              <MDButton
                                className="eliminate_butn"
                                onClick={() =>
                                  handleStatus(hotel.id, "ELIMINATE")
                                }
                                disabled={
                                  ticketStatuses[hotel.id] ||
                                  hotel.status !== "PENDING"
                                }
                              >
                                ELIMINATE
                              </MDButton>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-md-4 d-flex justify-content-end  airlinetkt">
                            <div className="col-md-12 text-end">
                              <div className="ticket_detail_butns">
                                <MDButton
                                  className={`accept_butn ${hotel?.status == "ACCEPT" && "accepted_butn"
                                    }`}
                                  disabled={hotel?.status == "ACCEPT"}
                                  onClick={() =>
                                    handleStatus(hotel.id, "ACCEPT")
                                  }
                                >
                                  {hotel?.status == "ACCEPT"
                                    ? "ACCEPTED"
                                    : "ACCEPT"}
                                </MDButton>

                                <MDButton
                                  className={`${hotel?.status == "REJECT"
                                    ? "rejected_butn"
                                    : "reject_butn"
                                    }`}
                                  disabled={hotel?.status == "REJECT"}
                                  onClick={() =>
                                    handleStatus(hotel.id, "REJECT")
                                  }
                                >
                                  {hotel?.status == "REJECT"
                                    ? "REJECTED"
                                    : "REJECT"}
                                </MDButton>

                                <MDButton
                                  className={`${hotel?.status == "ELIMINATE"
                                    ? "eliminated_butn"
                                    : "eliminate_butn"
                                    }`}
                                  disabled={hotel?.status == "ELIMINATE"}
                                  onClick={() =>
                                    handleStatus(hotel.id, "ELIMINATE")
                                  }
                                >
                                  {hotel?.status == "ELIMINATE"
                                    ? "ELIMINATED"
                                    : "ELIMINATE"}
                                </MDButton>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="row px-2">
                  <div className="col-md-5">
                    <div className="ticket_details">
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >
                        <span
                          style={{
                            color: "#61a108",
                            fontWeight: "bold",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hotel?.match_percentage}%
                        </span>Matched
                      </MDTypography>
                    </div>

                    <ul className="row">
                      {hotel?.percentage_changes?.split(",")?.map((percent, i) => {

                        return (
                          <li
                            className="col-md-12 hotel_desc_list"
                            key={i}
                          >
                            {percent}
                          </li>
                        );

                      })}
                    </ul>
                  </div>

                  <div className="col-md-7">
                    <div className="ticket_details">
                      <MDTypography
                        variant="p"
                        className="ticket_title"
                      >Amenities:
                      </MDTypography>
                    </div>
                    <ul className="row">
                      {hotel.description_text
                        ?.split(",")
                        .map((value) => {
                          if (value !== "") {
                            return (
                              <li
                                className="col-md-6 hotel_desc_list"
                                key={index}
                              >
                                {value}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
                {/* <div>
                  <ul className="row">
                    {hotel.description_text?.split(",").map((value) => {
                      if (value !== "") {
                        return (
                          <li className="col-md-4 hotel_desc_list" key={index}>
                            {value}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div> */}
              </div>
            );
          })}
        </>
      ) : (
        <MDTypography variant="h6" color="textSecondary">
          No Substitutes Found
        </MDTypography>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status == "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status == "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Reject this offer.
              </MDTypography>
            }

            {status == "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }
            {status !== "ACCEPT" && (
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">
                    Select Reason
                  </InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelBooking;
