import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import { getAdminList } from "utils/api";
import { userPermission } from "utils/api";
import { toast } from "react-toastify";
import { getPermissionByID } from "utils/api";
import Loader from "components/Loader";

function UpdatePermission() {
  const theme = useTheme();
  const [adminData, setAdminData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    add: false,
    view: false,
    change: false,
    delete: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [name]: checked,
    }));
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  const fetchAdminData = async () => {
    try {
      const response = await getAdminList();
      if (response?.status == 200) {
        setAdminData(response.data);
      } else {
        setAdminData([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const [selectedAdmins, setSelectedAdmins] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    const selectedItems = adminData
      .filter((item) => value.includes(item?.email))
      .map((item) => ({ id: item?.id, email: item?.email }));

    setSelectedAdmins(selectedItems);
    setLoading(true);
    getPermissionByID(selectedItems[0]?.id)
      .then((res) => {
        setLoading(false);
        if (res?.data?.permissions?.length > 0) {
          const updatedPermissions = {
            add: false,
            view: false,
            change: false,
            delete: false,
          };
          res?.data?.permissions?.forEach((permission) => {
            if (permission.codename.includes("add")) {
              updatedPermissions.add = true;
            } else if (permission.codename.includes("view")) {
              updatedPermissions.view = true;
            } else if (permission.codename.includes("change")) {
              updatedPermissions.change = true;
            } else if (permission.codename.includes("delete")) {
              updatedPermissions.delete = true;
            }
          });
          setPermissions(updatedPermissions);
        } else {
          setPermissions({
            add: false,
            view: false,
            change: false,
            delete: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const selectedEmails = selectedAdmins?.map((admin) => admin?.email);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatePermissionsCodenames = Object.keys(permissions)
      .filter((key) => permissions[key])
      .map((key) => `${key}_user`);

    const removePermissionsCodenames = Object.keys(permissions)
      .filter((key) => !permissions[key])
      .map((key) => `${key}_user`);

    const formData = {
      user_id: selectedAdmins[0]?.id,
      permissions: [
        { action: "grant", permissions_codenames: updatePermissionsCodenames },
        { action: "revoke", permissions_codenames: removePermissionsCodenames },
      ],
    };

    console.log("updatePermissionsCodenames", formData);

    const successMsj = "Successfully Updated Permission ";
    userPermission(formData)
      .then((res) => {
        if (res.status == 200) {
          toast.success(successMsj, {
            theme: "colored",
          });
        } else {
          toast.error("Failed to permission", {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <MDBox pt={3} mx={3} my={3}>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Admin</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedEmails}
                  onChange={handleChange}
                  input={<OutlinedInput label="Email" />}
                >
                  {adminData?.length > 0 ? (
                    adminData?.map((item) => (
                      <MenuItem key={item.id} value={item.email}>
                        {item.email}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </MDBox>

          <MDBox mx={4}>
            <Grid container>
              <Grid item md={3}>
                <MDTypography variant="h6">User</MDTypography>
              </Grid>
              <Grid item md={9}>
                <FormGroup className="permission_checkbox">
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.add}
                          onChange={handleCheckboxChange}
                          name="add"
                        />
                      }
                      label="Add"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.view}
                          onChange={handleCheckboxChange}
                          name="view"
                        />
                      }
                      label="List"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.change}
                          onChange={handleCheckboxChange}
                          name="change"
                        />
                      }
                      label="Update"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.delete}
                          onChange={handleCheckboxChange}
                          name="delete"
                        />
                      }
                      label="Delete"
                    />
                  </div>
                </FormGroup>
              </Grid>
            </Grid>
            {/* <Grid container>
                  <Grid item md={3}>
                    <MDTypography variant="h6">Booking</MDTypography>
                  </Grid>
                  <Grid item md={9}>
                    <FormGroup className="permission_checkbox_booking">
                      <div>
                        <FormControlLabel control={<Checkbox />} label="List" />
                      </div>
                    </FormGroup>
                  </Grid>
                </Grid> */}
            <div className="text-end mt-5">
              <Button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="submit_buton"
              >
                Save
              </Button>
            </div>
          </MDBox>
        </>
      )}
    </>
  );
}

export default UpdatePermission;
