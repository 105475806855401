import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import airlinrlogo from "assets/img/airlinrlogo.png";
import MDTypography from "components/MDTypography";
import plane from "assets/img/plane.svg";
import MDButton from "components/MDButton";
import { Pagination } from "@mui/material";

const FlightSubstitute = ({ substitutes, loading, current_price }) => {
  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(substitutes?.length / itemsPerPage);

  const displayedItems = substitutes?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  return (
    <>
      <MDTypography variant="p" className="p-title">
        Substitute Tickets
      </MDTypography>
      {loading ? (
        <>
          <h5>Loading ..</h5>
        </>
      ) : (
        <>
          {displayedItems.length > 0 ? (
            displayedItems?.map((ticket, index) => (
              <div key={index} className="container-fluid p-3 mb-3 subtiktmain">
                {
                  ticket?.return_offer ?
                    <div
                      className="row align-items-center"
                      style={{ alignItems: "center" }}
                    >
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-6 d-flex airlinetkt">
                            <div
                              className="col-md-6 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <img src={airlinrlogo} alt="" className="img-fluid" />
                              <div className="ticktnam mx-2">
                                <p className="ticktptag12">{ticket?.outbound_offer?.airline_name}</p>
                                <p className="ticktptag21" style={{ fontSize: "13px" }}>
                                  <span
                                    style={{
                                      color: "#61a108",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {ticket?.outbound_offer?.match_percentage}%
                                  </span>
                                  &nbsp;Matched
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 " style={{ marginTop: "20px" }}>
                              <p className="fstptag">{ticket?.outbound_offer?.price}</p>
                              <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                                You Can Save{" "}
                                <span
                                  style={{
                                    color: "#61a108",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {ticket?.outbound_offer?.saved_amount}
                                  {/* $ {getSaving(current_price, ticket?.outbound_offer?.price)} */}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 Dtime">
                            <div className="Dtimelist">
                              <ul className="ul-list">
                                <p className="ullist">
                                  {ticket?.outbound_offer?.departure_time?.split(" ")?.[0]}
                                  <span className="depature_time">
                                    {`${ticket?.outbound_offer?.departure_time?.split(" ")?.[1]} ${ticket?.outbound_offer?.departure_time?.split(" ")?.[2]
                                      }`}
                                  </span>
                                </p>

                                <p className="depature_code">
                                  {ticket?.outbound_offer?.departure_iata_code}
                                </p>
                              </ul>
                            </div>
                            <div className="Dtimelist">
                              <ul style={{ padding: "0" }}>
                                <img
                                  src={plane}
                                  alt="Custom Plane"
                                  width="68px"
                                  style={{ marginTop: "20px" }}
                                />
                                <li className="ullist">
                                  {getTime(
                                    ticket?.outbound_offer?.departure_time,
                                    ticket?.outbound_offer?.arrival_time
                                  )}
                                </li>
                              </ul>
                            </div>
                            <div className="Dtimelist">
                              <ul className="ul-list">
                                <p className="ullist">
                                  {ticket?.outbound_offer?.arrival_time?.split(" ")?.[0]}
                                  <span className="depature_time">
                                    {`${ticket?.outbound_offer?.arrival_time?.split(" ")?.[1]} ${ticket?.outbound_offer?.arrival_time?.split(" ")?.[2]
                                      }`}
                                  </span>
                                </p>
                                <p className="depature_code">
                                  {ticket?.outbound_offer?.arrival_iata_code}
                                </p>
                              </ul>
                            </div>
                          </div>
                          <div className="px-5">
                            <ul className="row mt-2">
                              {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                                if (value !== "") {
                                  return (
                                    <li
                                      className="col-md-12 hotel_desc_list"
                                      key={index}
                                    >
                                      {value}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>

                          <div className="col-md-6 d-flex airlinetkt">
                            <div
                              className="col-md-6 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <img src={airlinrlogo} alt="" className="img-fluid" />
                              <div className="ticktnam mx-2">
                                <p className="ticktptag12">{ticket?.return_offer?.airline_name}</p>
                                <p className="ticktptag21" style={{ fontSize: "13px" }}>
                                  <span
                                    style={{
                                      color: "#61a108",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {ticket?.return_offer?.match_percentage}%
                                  </span>
                                  &nbsp;Matched
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 " style={{ marginTop: "20px" }}>
                              <p className="fstptag">{ticket?.return_offer?.price}</p>
                              <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                                You Can Save{" "}
                                <span
                                  style={{
                                    color: "#61a108",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {ticket?.return_offer?.saved_amount}
                                  {/* $ {getSaving(current_price, ticket?.outbound_offer?.price)} */}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 Dtime">
                            <div className="Dtimelist">
                              <ul className="ul-list">
                                <p className="ullist">
                                  {ticket?.return_offer?.departure_time?.split(" ")?.[0]}
                                  <span className="depature_time">
                                    {`${ticket?.return_offer?.departure_time?.split(" ")?.[1]} ${ticket?.return_offer?.departure_time?.split(" ")?.[2]
                                      }`}
                                  </span>
                                </p>

                                <p className="depature_code">
                                  {ticket?.return_offer?.departure_iata_code}
                                </p>
                              </ul>
                            </div>
                            <div className="Dtimelist">
                              <ul style={{ padding: "0" }}>
                                <img
                                  src={plane}
                                  alt="Custom Plane"
                                  width="68px"
                                  style={{ marginTop: "20px" }}
                                />
                                <li className="ullist">
                                  {getTime(
                                    ticket?.return_offer?.departure_time,
                                    ticket?.return_offer?.arrival_time
                                  )}
                                </li>
                              </ul>
                            </div>
                            <div className="Dtimelist">
                              <ul className="ul-list">
                                <p className="ullist">
                                  {ticket?.return_offer?.arrival_time?.split(" ")?.[0]}
                                  <span className="depature_time">
                                    {`${ticket?.return_offer?.arrival_time?.split(" ")?.[1]} ${ticket?.return_offer?.arrival_time?.split(" ")?.[2]
                                      }`}
                                  </span>
                                </p>
                                <p className="depature_code">
                                  {ticket?.return_offer?.arrival_iata_code}
                                </p>
                              </ul>
                            </div>
                          </div>
                          <div className="px-5">
                            <ul className="row mt-2">
                              {ticket?.return_offer?.percentage_changes?.split(",")?.map((value) => {
                                if (value !== "") {
                                  return (
                                    <li
                                      className="col-md-12 hotel_desc_list"
                                      key={index}
                                    >
                                      {value}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex airlinetkt">
                        <div className="col-md-12">
                          <div className="text-center">
                            <div>
                              <p className="fstptag text-enter"> Total Price</p>
                              <p className="fstptag text-center"> {ticket?.total_price}</p>
                              {/* <p
                                className="sndptag"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                You Can Save{" "}
                                <span
                                  style={{
                                    color: "#61a108",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {ticket?.outbound_offer?.saved_amount}

                                </span>
                              </p> */}
                            </div>
                          </div>
                          <div className="ticket_detail_butns justify-content-center">
                            <MDButton
                              className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" && "accepted_butn"
                                } ${ticket?.outbound_offer?.status == "REJECT" && "rejected_butn"}
                                            ${ticket?.outbound_offer?.status == "ELIMINATE" &&
                                "eliminate_butn"
                                }`}
                            >
                              {ticket?.outbound_offer?.status == "ACCEPT" && "ACCEPTED"}
                              {ticket?.outbound_offer?.status == "ELIMINATE" && "ELIMINATED"}
                              {ticket?.outbound_offer?.status == "REJECT" && "REJECTED"}
                              {ticket?.outbound_offer?.status !== "REJECT" &&
                                ticket?.outbound_offer?.status !== "ACCEPT" &&
                                ticket?.outbound_offer?.status !== "ELIMINATE" &&
                                ticket?.outbound_offer?.status}
                              {/* {ticket?.outbound_offer?.status} */}
                            </MDButton>
                            {/* <MDButton className="eliminate_butn">
                        Eliminate
                      </MDButton> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div
                      className="row align-items-center"
                      style={{ alignItems: "center" }}
                    >
                      <div className="col-md-5 d-flex airlinetkt">
                        <div
                          className="col-md-6 d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <img src={airlinrlogo} alt="" className="img-fluid" />
                          <div className="ticktnam mx-2">
                            <p className="ticktptag12">{ticket?.outbound_offer?.airline_name}</p>
                            <p className="ticktptag21" style={{ fontSize: "13px" }}>
                              <span
                                style={{
                                  color: "#61a108",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {ticket?.outbound_offer?.match_percentage}%
                              </span>
                              &nbsp;Matched
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 " style={{ marginTop: "20px" }}>
                          <p className="fstptag">{ticket?.outbound_offer?.price}</p>
                          <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                            You Can Save{" "}
                            <span
                              style={{
                                color: "#61a108",
                                fontWeight: "bold",
                                fontSize: "13px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ticket?.outbound_offer?.saved_amount}
                              {/* $ {getSaving(current_price, ticket?.outbound_offer?.price)} */}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5 Dtime">
                        <div className="Dtimelist">
                          <ul className="ul-list">
                            <p className="ullist">
                              {ticket?.outbound_offer?.departure_time?.split(" ")?.[0]}
                              <span className="depature_time">
                                {`${ticket?.outbound_offer?.departure_time?.split(" ")?.[1]} ${ticket?.outbound_offer?.departure_time?.split(" ")?.[2]
                                  }`}
                              </span>
                            </p>

                            <p className="depature_code">
                              {ticket?.outbound_offer?.departure_iata_code}
                            </p>
                          </ul>
                        </div>
                        <div className="Dtimelist">
                          <ul style={{ padding: "0" }}>
                            <img
                              src={plane}
                              alt="Custom Plane"
                              width="68px"
                              style={{ marginTop: "20px" }}
                            />
                            <li className="ullist">
                              {getTime(
                                ticket?.outbound_offer?.departure_time,
                                ticket?.outbound_offer?.arrival_time
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="Dtimelist">
                          <ul className="ul-list">
                            <p className="ullist">
                              {ticket?.outbound_offer?.arrival_time?.split(" ")?.[0]}
                              <span className="depature_time">
                                {`${ticket?.outbound_offer?.arrival_time?.split(" ")?.[1]} ${ticket?.outbound_offer?.arrival_time?.split(" ")?.[2]
                                  }`}
                              </span>
                            </p>
                            <p className="depature_code">
                              {ticket?.outbound_offer?.arrival_iata_code}
                            </p>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex airlinetkt">
                        <div className="col-md-12">
                          <div className="ticket_detail_butns justify-content-center">
                            <MDButton
                              className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" && "accepted_butn"
                                } ${ticket?.outbound_offer?.status == "REJECT" && "rejected_butn"}
                                          ${ticket?.outbound_offer?.status == "ELIMINATE" &&
                                "eliminate_butn"
                                }`}
                            >
                              {ticket?.outbound_offer?.status == "ACCEPT" && "ACCEPTED"}
                              {ticket?.outbound_offer?.status == "ELIMINATE" && "ELIMINATED"}
                              {ticket?.outbound_offer?.status == "REJECT" && "REJECTED"}
                              {ticket?.outbound_offer?.status !== "REJECT" &&
                                ticket?.outbound_offer?.status !== "ACCEPT" &&
                                ticket?.outbound_offer?.status !== "ELIMINATE" &&
                                ticket?.outbound_offer?.status}
                              {/* {ticket?.outbound_offer?.status} */}
                            </MDButton>
                            {/* <MDButton className="eliminate_butn">
                      Eliminate
                    </MDButton> */}
                          </div>
                        </div>
                      </div>

                      <div className="px-5">
                        <ul className="row mt-2">
                          {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                            if (value !== "") {
                              return (
                                <li
                                  className="col-md-12 hotel_desc_list"
                                  key={index}
                                >
                                  {value}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                }
              </div>
            ))
          ) : (
            <p>No substitute offers available</p>
          )}

          {displayedItems?.length > 0 && (
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          )}
        </>
      )}
    </>
  );
};

export default FlightSubstitute;
