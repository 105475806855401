import React, { useState } from "react";
import "./Contactus.css";
import { contactUs } from "utils/api";
import { toast } from "react-toastify";

const Contactus = () => {
  // State for storing form data
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  // State for error messages
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const [loading, setLoading] = useState(false)

  // Handle input change with validation onChange
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Validate the specific field as the user types
    validateField(name, value);
  };

  // Validate a single field based on its name and value
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "first_name":
        if (!value) error = "First Name is required";
        break;
      case "last_name":
        if (!value) error = "Last Name is required";
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Please enter a valid email";
        }
        break;
      case "phone_number":
        if (!value) {
          error = "Phone number is required";
        } else if (!/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits";
        }
        break;
      case "message":
        if (!value) error = "Message is required";
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // Validate all fields before submission
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // First Name validation
    if (!formData.first_name) {
      newErrors.first_name = "First Name is required";
      isValid = false;
    }

    // Last Name validation
    if (!formData.last_name) {
      newErrors.last_name = "Last Name is required";
      isValid = false;
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
      isValid = false;
    }

    // phone_number validation
    if (!formData.phone_number) {
      newErrors.phone_number = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone number must be 10 digits";
      isValid = false;
    }

    // Message validation
    if (!formData.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      console.log("Form Submitted", formData);
      contactUs(formData)
        .then((res) => {
          setLoading(false)
          if (res.status == 200) {
            toast.success(res?.data?.message, {
              theme: "colored",
            });
            setFormData({
              first_name: "",
              last_name: "",
              email: "",
              phone_number: "",
              message: "",
            });
            setErrors({
              first_name: "",
              last_name: "",
              email: "",
              phone_number: "",
              message: "",
            });
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error("Something went wrong", {
            theme: "colored",
          });
        });
    }
  };

  return (
    <div className="contact-container">
      <div className="form-section">
        <form onSubmit={handleSubmit}>
          <div className="form-group row my-2">
            <div className="col-md-6">
              <input
                type="text"
                className="formcontrol"
                name="first_name"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
              />
              {errors.first_name && (
                <p className="error">{errors.first_name}</p>
              )}
            </div>

            <div className="col-md-6">
              <input
                type="text"
                className="formcontrol"
                name="last_name"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
              />
              {errors.last_name && <p className="error">{errors.last_name}</p>}
            </div>
          </div>

          <div className="form-group row my-2">
            <div className="col-md-6">
              <input
                type="email"
                className="formcontrol"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="col-md-6">
              <input
                type="text"
                className="formcontrol"
                name="phone_number"
                placeholder="Phone number"
                value={formData.phone_number}
                onChange={handleChange}
              />
              {errors.phone_number && (
                <p className="error">{errors.phone_number}</p>
              )}
            </div>
          </div>

          <div className="form-group row my-2">
            <div className="col-md-12">
              <textarea
                className="form-control"
                name="message"
                placeholder="How We Can Help You?"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
          </div>

          <button type="submit" className="sendButton my-2">
            {loading ? "Loading..." : "Send Message"}
          </button>
        </form>
      </div>

      <div className="text-section">
        <h5>Contact Us</h5>
        <h2>Are You Ready To AiRebooker?</h2>
      </div>
    </div>
  );
};

export default Contactus;
