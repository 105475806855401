import React from 'react';
import './service.css'
import servicecard1 from 'assets/img/servicecard1.png' 


const Services = () => {
  return (
   <div style={{}}>
     <div className="services-container">
    <div className="background-wrapper">
     
      <div className="image-overlay">
        <img src={servicecard1} alt="Overlapping Image" className="overlapping-image" />
      </div>
   
    <div className="services-box">
    <div className='srvscontent'>
    <h2>Services</h2>
      <p className='serviceptag'>
        Lorem ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
      </p>
      <button className="btn btn-primary">Know More</button>
    </div>
    </div> </div>
  </div>
   </div>
  );
};

export default Services;
