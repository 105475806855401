import React from 'react';
import './NewsletterFooter.css'; 
import footerlogo from 'assets/img/footerlogo.png';
import sociallogo1 from 'assets/img/sociallogo1.png';
import sociallogo2 from 'assets/img/sociallogo2.png';
import sociallogo3 from 'assets/img/sociallogo3.png';
import sociallogo4 from 'assets/img/sociallogo4.png';

const NewsletterFooter = () => {
  return (
    <div className="main-container">
     
      <div className="newsletter-container">
        <div className="newsletter-content">
          <h5>Newsletter</h5>
          <h2>Let's stay in touch!</h2>
          <p>We'll send you a nice letter once per week. No Spam.</p>
        </div>
        <div className="newsletter-input">
          <input type="email" placeholder="Enter your email" />
          <button type="submit" className="btn btn-primary">Subscribe</button>
        </div>
      </div>

    
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo-section">
            <img src={footerlogo} alt="Logo" className="footer-logo" />
            <p className="footerp">Design amazing digital experiences <br /> that create happiness in the world.</p>
          </div>
          <div className="footer-links-section">
            <div>
              <h5>Product</h5>
              <ul>
                <li>Overview</li>
                <li>Features</li>
                <li>Solutions</li>
                <li>Tutorials</li>
                <li>Pricing</li>
                <li>Roadmaps</li>
              </ul>
            </div>
            <div>
              <h5>Company</h5>
              <ul>
                <li>About Us</li>
                <li>Careers</li>
                <li>Events</li>
                <li>News</li>
                <li>Contact</li>
              </ul>
            </div>
            <div>
              <h5>Resources</h5>
              <ul>
                <li>Help Center</li>
                <li>Newsletter</li>
                <li>Support</li>
                <li>Tutorials</li>
              </ul>
            </div>
            <div>
              <h5>Social</h5>
              <ul>
                <li>Twitter</li>
                <li>LinkedIn</li>
                <li>Facebook</li>
                <li>GitHub</li>
                <li>Dribbble</li>
              </ul>
            </div>
            <div>
              <h5>Legal</h5>
              <ul>
                <li>Terms</li>
                <li>Privacy</li>
                <li>Cookies</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="social-links-section">
          <div className="social-links">
            <p>2024 AiRebooker. All rights reserved.</p>
          </div>
          <div className="social-icons">
            <ul className="social-list">
              <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><img src={sociallogo1}  style={{ width: '11px', margin: '0 10px' }} alt="Facebook" /></a></li>
              <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><img src={sociallogo2}  style={{ width: '11px', margin: '0 10px' }} alt="Twitter" /></a></li>
              <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><img src={sociallogo3} style={{ width: '11px', margin: '0 10px' }} alt="LinkedIn" /></a></li>
              <li><a href="https://github.com" target="_blank" rel="noopener noreferrer"><img src={sociallogo4}  style={{ width: '11px', margin: '0 10px' }} alt="GitHub" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterFooter;
