import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUserByID } from "utils/userApi";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AvatorImg from "assets/images/defaultAvatar.jpg";
import MDInput from "components/MDInput";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import FlightImg from "assets/user-dash/flight.png";
import HotelImg from "assets/user-dash/hotelimg.png";
import CarImg from "assets/user-dash/carImage.png";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { getPermissionByID } from "utils/api";
import { deleteUser } from "utils/userApi";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Rebook Flight", "Rebook Hotel", "Rebook Car"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [personName, setPersonName] = useState("Rebook Flight");
  const [pageData, setPageData] = useState([]);
  const [deletPermision, setDeletPermision] = useState(false);
  const [updatePermision, setUpdatePermision] = useState(false);
  const [userType, setUserType] = useState(null);
  const [show, setShow] = useState(false);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handleDeleteUser = async (id) => {
    setShow(id);
  };

  const handleClose = () => setShow(false);

  const handleDeleteAdmin = async () => {
    try {
      setLoading(true);
      await deleteUser(show);
      toast.success("User successfully deleted", {
        theme: "colored"
      })
      setShow(false);
      setLoading(false);
      navigate("/user-management")
    } catch (error) {
      toast.error("Failed to deleted", {
        theme: "colored"
      })
      setShow(false);
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    getUserByID(id)
      .then((res) => {
        setLoading(false);
        setUser(res.data);
        setPageData(res.data.flight_data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [id]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setPersonName(value);
    if (value == "Rebook Car") {
      setPageData(user?.car_data);
    } else if (value == "Rebook Hotel") {
      setPageData(user?.hotel_data);
    } else {
      setPageData(user?.flight_data);
    }
  };

  const redirection = (id, type) => {
    let final_id = encodeIdWithType(id, type);
    navigate(`/flight-details/${final_id}`);
  };

  useEffect(() => {
    getUserPermission();
  }, []);

  const getUserPermission = () => {
    const user = JSON.parse(localStorage.getItem("active-user"));
    if (user) {
      setUserType(user.user_type);
      getPermissionByID(user.id)
        .then((res) => {
          const data = res?.data?.permissions;
          const updateUser = data?.filter((item) => {
            return item.codename == "change_user";
          });
          setUpdatePermision(updateUser?.[0]);
          const deleteUser = data?.filter((item) => {
            return item.codename == "delete_user";
          });
          setDeletPermision(deleteUser?.[0]);
        })
        .catch((error) => {
          console.error("Error fetching permission:", error);
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
              <MDTypography
                varient="h1"
                className="user_fullname"
              >{`${user?.first_name} ${user?.last_name}`}</MDTypography>
            </div>
            <div className="user_action_icons">
              {(updatePermision || userType == 1) && (
                <Link to={`/edit-user/${user.id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    className="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fillRule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                    />
                  </svg>
                </Link>
              )}

              {(deletPermision || userType == 1) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-trash3-fill"
                  viewBox="0 0 16 16"
                  onClick={() => {
                    handleDeleteUser(user?.id);
                  }}
                >
                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                </svg>
              )}
            </div>
          </div>

          <Card className="mt-4">
            <Grid container className="user_profile_container">
              <Grid item xs={12} md={3} className="user_detail_image">
                <div className="user_image_name">
                  <img
                    src={
                      user?.image
                        ? `${process.env.REACT_APP_API_URL}${user?.image}`
                        : AvatorImg
                    }
                    className="user_newimage"
                  />
                  <MDTypography varient="h1" className="user_detail_fullname">
                    {`${user?.first_name} ${user?.last_name}`}
                  </MDTypography>
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                {/* <MDTypography varient="p" className="general_info_text">
                  General Info
                </MDTypography> */}
                <div className="user_details_section">
                  <Grid container className="user_info_grid">
                    <Grid item xs={12} md={3}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          First Name
                        </MDTypography>
                        <MDInput
                          value={user?.first_name}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Last Name
                        </MDTypography>
                        <MDInput
                          value={user?.last_name}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Email
                        </MDTypography>
                        <MDInput
                          value={user?.email}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Phone Number
                        </MDTypography>
                        <MDInput
                          value={user?.phone_number}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <MDTypography variant="h6">Trips</MDTypography>
                <div>
                  <div>
                    <MDInput placeholder="Search" />
                    <FormControl sx={{ width: 200, mx: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {names?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              {personName == "Rebook Flight" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((flight) => {
                      return (
                        <Card className="mt-4">
                          {
                            flight?.children?.length > 0 ?
                              <div
                                className="airline_header row"
                                onClick={() => redirection(flight?.id, "flights")}
                              >
                                <div className="airline_data col-md-3">
                                  <img src={AirlineLogo} alt="airline logo" />
                                  <div className="airline_title_section">
                                    <MDTypography
                                      variant="h6"
                                      color="white"
                                      className="air_line_title"
                                    >
                                      {flight?.airline}
                                    </MDTypography>
                                    <div>
                                      {flight?.one_way ? (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          One Way
                                        </MDTypography>
                                      ) : (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Round Trip
                                        </MDTypography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <MDTypography
                                    variant="h6"
                                    className="airline_Text"
                                  >
                                    ${flight?.price}
                                  </MDTypography>
                                </div>

                                <div className="col-md-4">
                                  <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                                    Depature
                                  </MDTypography>
                                  <div
                                    className="ticket_detail_heading"
                                  >
                                    <MDTypography variant="h6">
                                      {flight?.departure_city?.split(",")[0]}
                                      -to-
                                      {flight?.destination_city?.split(",")[0]}
                                    </MDTypography>
                                    <div className="ticket_details">

                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_number}
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        1 Adult
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_class}
                                      </MDTypography>
                                    </div>
                                    <MDTypography variant="h6" className="ticket_title derature_date_time">
                                      {flight?.departure_date} {flight?.departure_time}
                                    </MDTypography>
                                    <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                      {flight?.return_date && flight?.Arrives_time
                                        ? `${flight.return_date} ${flight.Arrives_time}`
                                        : flight?.return_date}
                                    </MDTypography>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div>
                                    {flight.children && flight.children.length > 0 && (
                                      <div>
                                        {flight?.children?.map((childFlight, index) => (
                                          <div key={index} className="child_flight_info">
                                            <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                                              Return
                                            </MDTypography>
                                            <div
                                              className="ticket_detail_heading"
                                            >
                                              <MDTypography variant="h6">
                                                {childFlight?.departure_city?.split(",")[0]}
                                                -to-
                                                {childFlight?.destination_city?.split(",")[0]}
                                              </MDTypography>
                                              <div className="ticket_details">

                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  {childFlight?.flight_number}
                                                </MDTypography>
                                                <span>|</span>
                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  1 Adult
                                                </MDTypography>
                                                <span>|</span>
                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  {childFlight?.flight_class}
                                                </MDTypography>
                                              </div>
                                              <MDTypography variant="h6" className="ticket_title derature_date_time">
                                                {childFlight?.departure_date} {childFlight?.departure_time}
                                              </MDTypography>
                                              <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                                {childFlight?.return_date && childFlight?.Arrives_time
                                                  ? `${childFlight.return_date} ${childFlight.Arrives_time}`
                                                  : childFlight?.return_date}
                                              </MDTypography>
                                            </div>
                                          </div>

                                        ))}
                                      </div>
                                    )}
                                  </div>

                                </div>
                              </div>
                              :
                              <div
                                className="airline_header row"
                                onClick={() => redirection(flight?.id, "flights")}
                              >
                                <div className="airline_data col-md-3">
                                  <img src={AirlineLogo} alt="airline logo" />
                                  <div className="airline_title_section">
                                    <MDTypography
                                      variant="h6"
                                      color="white"
                                      className="air_line_title"
                                    >
                                      {flight?.airline}
                                    </MDTypography>
                                    <div>
                                      {flight?.one_way ? (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          One Way
                                        </MDTypography>
                                      ) : (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Round Trip
                                        </MDTypography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <MDTypography
                                    variant="h6"
                                    className="airline_Text"
                                  >
                                    ${flight?.price}
                                  </MDTypography>
                                </div>

                                <div className="col-md-8">
                                  <div
                                    className="ticket_detail_heading"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <MDTypography variant="h6">
                                      {flight?.departure_city?.split(",")[0]}
                                      -to-
                                      {flight?.destination_city?.split(",")[0]}
                                    </MDTypography>
                                    <div className="ticket_details">

                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_number}
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        1 Adult
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_class}
                                      </MDTypography>
                                    </div>
                                    <MDTypography variant="h6" className="ticket_title derature_date_time">
                                      {flight?.departure_date} {flight?.departure_time}
                                    </MDTypography>
                                    <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                      {flight?.return_date && flight?.Arrives_time
                                        ? `${flight.return_date} ${flight.Arrives_time}`
                                        : flight?.return_date}
                                    </MDTypography>
                                  </div>
                                </div>
                              </div>
                          }
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              {personName == "Rebook Hotel" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems.map((hotel, index) => {
                      return (
                        <Card className="mt-4">
                          <div
                            className="airline_header row"
                            onClick={() => redirection(hotel?.id, "hotels")}
                          >
                            <div className="airline_data col-md-4">
                              <img src={HotelImg} alt="airline logo" />
                              <div className="airline_title_section">
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="air_line_title"
                                >
                                  {hotel?.hotel_name}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Check in: {hotel?.check_in_date}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Check out: {hotel?.check_out_date}
                                </MDTypography>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MDTypography
                                variant="h6"
                                className="airline_Text"
                              >
                                {hotel?.guest_name}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                color="white"
                                className="user_ticket_title"
                              >
                                Booking reference: {hotel?.booking_reference}
                              </MDTypography>
                            </div>
                            <div className="col-md-4">
                              <div className="ticket_detail_heading">
                                <MDTypography variant="h6">
                                  {hotel?.rental_price}
                                </MDTypography>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    className="ticket_title"
                                  >
                                    {hotel?.room_type}
                                  </MDTypography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              {personName == "Rebook Car" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((car) => {
                      return (
                        <Card className="mt-4">
                          <div
                            className="airline_header row"
                            onClick={() => redirection(car?.id, "cars")}
                          >
                            <div className="airline_data col-md-6">
                              <img src={CarImg} alt="airline logo" />
                              <div className="airline_title_section">
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="air_line_title"
                                >
                                  {car?.car_model}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  {`Pick up: ${car?.pickup_date}, ${car?.pickup_location?.length > 20
                                      ? `${car?.pickup_location?.slice(
                                        0,
                                        20
                                      )}...`
                                      : car?.pickup_location
                                    }`}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Drop: {car?.dropoff_date},{" "}
                                  {car?.dropoff_location?.length > 20
                                    ? `${car?.dropoff_location?.slice(
                                      0,
                                      20
                                    )}...`
                                    : car?.dropoff_location}
                                </MDTypography>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <MDTypography
                                variant="h6"
                                className="airline_Text"
                              >
                                {car?.renter_name}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                color="white"
                                className="user_ticket_title"
                              >
                                Booking reference: {car?.booking_reference}
                              </MDTypography>
                            </div>

                            <div className="col-md-3">
                              <div className="ticket_detail_heading">
                                <MDTypography variant="h6">
                                  {car?.rental_price}
                                </MDTypography>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    className="ticket_title"
                                  >
                                    {car?.rental_company}
                                  </MDTypography>
                                </div>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    color="white"
                                    className="user_ticket_title"
                                  >
                                    {car?.rental_class}
                                  </MDTypography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </MDBox>
          </Card>
        </MDBox>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              className="bi bi-x-circle cancel_icon mb-4"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            <MDTypography variant="p" className="delete_msj">
              Do you really want to delete this User?
            </MDTypography>
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleDeleteAdmin();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
};

export default UserDetails;
