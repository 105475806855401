import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import MDButton from "components/MDButton";
import HotelImg from "assets/user-dash/hotelimg.png";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Card } from "@mui/material";
import Loader from "components/Loader";

const HotelBooking = ({ booking, substitutes, loading }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <MDBox py={3}>
            <div className="user_detail_section">
              <MDTypography variant="p" className="p-title">
                <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "}
                {booking?.hotel_name}
              </MDTypography>
            </div>
            <Card className="container-fluid mb-2 p-3">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <MDTypography
                    variant="p"
                    color="info"
                    className="Current-Plane"
                  >
                    Your Current Hotel Booking
                  </MDTypography>
                </div>
                <div className="col-md-8 dotted-gradient-border"></div>

                <div className="currentTicket mt-3">
                  <div className="col-md-4 airlinetick">
                    <div className="">
                      <img src={HotelImg} alt="" className="img-fluid" />
                    </div>
                    <div className="ticktname hotel_details_user">
                      <MDTypography variant="h6" className="airline_heading">
                        {booking?.hotel_name}
                      </MDTypography>
                      <MDTypography variant="p" className="ticket_title">
                        Check in: {booking?.check_in_date}
                      </MDTypography>
                      <MDTypography variant="p" className="ticket_title">
                        Check out: {booking?.check_out_date}
                      </MDTypography>

                      {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <MDTypography variant="h5" className="flight_details">
                      {booking?.guest_name}
                    </MDTypography>
                    <MDTypography variant="p" className="ticket_title">
                      Booking reference: {booking?.booking_reference}
                    </MDTypography>
                  </div>
                  <div className="col-md-3">
                    <MDTypography variant="h5" className="flight_details">
                      {booking?.rental_price}
                    </MDTypography>
                    <MDTypography variant="p" className="ticket_title">
                      {booking?.room_type}
                    </MDTypography>
                  </div>
                </div>
              </div>
            </Card>
          </MDBox>
          {substitutes.length > 0 ? (
            <>
              <MDTypography variant="p" className="p-title">
                Substitute Tickets
              </MDTypography>
              {substitutes.map((hotel, index) => (
                <div
                  key={index}
                  className="container-fluid p-3 mb-3 subtiktmain"
                >
                  <div className="airline_header substitutes_card_flight row">
                    <div className=" hotel_name_title col-md-5">
                      <MDTypography variant="h6" className="hotel_name">
                        {hotel.hotel_name}
                      </MDTypography>
                      <div className="ticket_details">
                        <MDTypography variant="p" className="ticket_title">
                          Check in: {hotel.check_in_date}
                        </MDTypography>
                      </div>
                      <div className="ticket_details">
                        <MDTypography variant="p" className="ticket_title">
                          Check out: {hotel.check_out_date}
                        </MDTypography>
                      </div>
                    </div>
                    <div className="flight_price col-md-2">
                      <MDTypography variant="p" className="fstptag">
                        {hotel.price_currency}&nbsp;
                        {hotel.price_total}
                      </MDTypography>
                      <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                        You Can Save{" "}
                        <span
                          style={{
                            color: "#61a108",
                            fontWeight: "bold",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hotel?.saved_amount}
                          {/* {hotel?.saved_amount?.toFixed(2)} */}
                          {/* $ {getSaving(hotel?.price?.total, item.rental_price)} */}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-2">
                      <MDTypography variant="p" className="ticket_title">
                        {hotel.adults} Adult
                      </MDTypography>
                      <span className="mx-1">|</span>
                      <MDTypography
                        variant="p"
                        className="ticket_title text-capitalize"
                      >
                        {hotel.room_type_estimated.bedType} SIZE BED
                      </MDTypography>
                    </div>
                    <div className="col-md-2">
                      <MDButton
                        className={`accept_butn ${
                          hotel?.status == "ACCEPT" && "accepted_butn"
                        } ${hotel?.status == "REJECT" && "rejected_butn"}
                                              ${
                                                hotel?.status == "ELIMINATE" &&
                                                "eliminate_butn"
                                              }`}
                      >
                        {hotel?.status == "ACCEPT" && "ACCEPTED"}
                        {hotel?.status == "ELIMINATE" && "ELIMINATED"}
                        {hotel?.status == "REJECT" && "REJECTED"}
                        {hotel?.status !== "REJECT" &&
                          hotel?.status !== "ACCEPT" &&
                          hotel?.status !== "ELIMINATE" &&
                          hotel?.status}
                        {/* {ticket.status} */}
                      </MDButton>
                      <br/>
                      <span className="selected_reason">
                        {hotel?.additional_information}
                      </span>
                    </div>
                    
                  </div>
                  <div>
                    <ul className="row">
                      {hotel.description_text?.split(",").map((value) => {
                        if (value !== "") {
                          return (
                            <li
                              className="col-md-4 hotel_desc_list"
                              key={index}
                            >
                              {value}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <MDTypography variant="h6" color="textSecondary">
              No Substitutes Found
            </MDTypography>
          )}
        </div>
      )}
    </>
  );
};

export default HotelBooking;
