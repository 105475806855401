export default function validator(name, value, second) {
    if (!value) {
        const newValue = name.replace(/_/g, " ")                    // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase());
        return `${newValue} is required`;
    } else {
        if (name === "first_name" || name === "last_name") {
            if (value.length < 2) {
                return "Min 2 characters required";
            } else {
                return false
            }
        } else if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                return "Invalid email address";
            } else {
                return false
            }
        } else if (name === "password"||name === "new_password") {
            if (value.length < 6) {
                return "Password must be at least 6 characters long";
            } else {
                return false
            }
        } else if (name === "confirm_password") {
            if (value !== second) {
                return "Password does not match";
            } else {
                return false
            }
        }
    }
}