import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button } from "@mui/material";
import NewPermissions from "./newPermission";
import UpdatePermission from "./updatePermision";

function Permissions() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Permissions
                </MDTypography>
                {/* {status == "Submit" ? (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setStatus("Update")}
                  >
                    Update
                  </Button>
                ):
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => setStatus("Submit")}
                  >
                    New Permission
                  </Button>
                } */}
              </MDBox>

              <UpdatePermission />
           
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Permissions;
