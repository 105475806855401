import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Airline from "assets/user-dash/airport.png";
import CarImage from "assets/user-dash/car_img.png";
import HotelImage from "assets/user-dash/hotel_img.png";
import RightArrow from "assets/user-dash/rightArrow.png";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";

function RebookCards({ active, data, handleChange }) {
  return (
    <MDBox>
      <Card className="main_card">
        <Grid container className="dashboard_outer">
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg" onClick={() => handleChange(1)}>
                    <div className="rebook_cards">
                      <div>
                        {" "}
                        <MDTypography variant="h6">Rebook <br/> Flight</MDTypography>
                        <img src={RightArrow} />
                      </div>
                      <div>
                      <img src={Airline} />
                      </div>
                    </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg" onClick={() => handleChange(2)}>
                    <div className="rebook_cards">
                      <div>
                        {" "}
                        <MDTypography variant="h6">Rebook <br/> Hotel</MDTypography>
                        <img src={RightArrow} />
                      </div>
                      <div>
                      <img src={HotelImage} />
                      </div>
                    </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg" onClick={() => handleChange(3)}>
                    <div className="rebook_cards">
                      <div>
                        {" "}
                        <MDTypography variant="h6">Rent <br/> Car</MDTypography>
                        <img src={RightArrow} />
                      </div>
                      <div>
                      <img src={CarImage} />
                      </div>
                    </div>
                </Card>
              </Grid>
              {/* <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg">
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      className="card_image_left"
                    >
                      <img src={Airline} />
                      <MDTypography variant="h6">
                        Rebook Flight
                      </MDTypography>
                      <img src={RightArrow} />
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} style={{cursor:"pointer"}}>
                      <Card className={active === 1 ? "card_right" : "card_right_white"} onClick={() => handleChange(1)}>
                        <MDTypography variant={active === 1 ? "p":"h6"} className={active === 1 && "card_name"}>
                          Atlanta
                        </MDTypography>
                        <MDTypography variant="p" className={active === 1 ? "card_date":"date_time"}>
                          Dep: 07:30 AM
                        </MDTypography>
                        <div className="center_text">
                          <MDTypography
                          variant={active === 1 ? "p":"h6"} className={active === 1 && "card_text_center"}
                          >
                            MCO LAX
                          </MDTypography>
                        </div>
                        <div className="end_text">
                          <MDTypography variant="p" className={active === 1 ? "card_date":"date_time"}>
                            Arrival: 11:15 AM
                          </MDTypography>
                          <MDTypography variant={active === 1 ? "p":"h6"} className={active === 1 && "card_name"}>
                            Los Angeles{" "}
                          </MDTypography>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg">
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      className="card_image_left"
                    >
                      <img src={HotelImage} />
                      <MDTypography variant="h6">Rebook Hotel</MDTypography>
                      <img src={RightArrow} />
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} style={{cursor:"pointer"}}>
                      <Card className={active === 2 ? "card_right" : "card_right_white"} onClick={() => handleChange(2)}>
                        <div className="hotel_detail">
                          <MDTypography variant={active === 2 ? "p":"h6"} className={active === 2 && "card_name"}>
                            Hotel Asphera
                          </MDTypography>
                          <div className="end_flex">
                            <MDTypography variant="p" className={active === 2 ? "card_date" : "date_time"}>
                              for 24 hours 28 Sep 2024
                            </MDTypography>
                            <MDTypography variant={active === 2 ? "p":"h6"} className={active === 2 && "card_name"}>$160</MDTypography>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="card_bg">
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      className="card_image_left"
                    >
                      <img src={CarImage} />
                      <MDTypography variant="h6">
                        Rent <br /> Car
                      </MDTypography>
                      <img src={RightArrow} />
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} style={{cursor:"pointer"}}>
                      <Card className={active === 3 ? "card_right" : "card_right_white"} onClick={() => handleChange(3)}>
                        <div className="hotel_detail">
                          <MDTypography variant={active === 3 ? "p":"h6"} className={active === 3 && "card_name"}>
                            Honda City
                          </MDTypography>
                          <div className="end_flex">
                            <MDTypography variant="p" className={active === 3 ? "card_date" : "date_time"}>
                              for 24 hours 28 Sep 2024
                            </MDTypography>
                            <MDTypography variant={active === 3 ? "p":"h6"} className={active === 3 && "card_name"}>
                              $27 /hr
                            </MDTypography>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

export default RebookCards;
