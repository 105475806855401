import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { addUser, getUserByID, updateUser } from "utils/userApi";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import validator from "utils/validate";
import { Grid } from "@mui/material";
import { getAdminByID } from "utils/api";
import { toast } from "react-toastify";
import { updateAdmin } from "utils/api";
import Loader from "components/Loader";

function AddAdmin() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiSuccess, setApiSucess] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    first_name: null,
    last_name: null,
    email: null,
    user_type: 3,
    password: null,
    confirm_password: null,
  });

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null,
  });

  useEffect(() => {
    if (!!id) {
      fetchUser();
    }
  }, [id]);

  const fetchUser = async () => {
    try {
      const response = await getAdminByID(id);
      const userData = response.data;
      setUser({
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        user_type: userData.user_type || "Admin",
        password: "",
        confirm_password: "",
      });
    } catch (error) {
      console.log("Failed to fetch user data");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validate(e);
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const validate = (event) => {
    const { name, value } = event.target;
    let error = validator(
      name,
      value,
      name === "confirm_password" && user.password
    );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    let empty = false;
    for (let i = 0; i < Object.keys(user).length; i++) {
      let error = false;
      if (id) {
        if (
          Object.keys(user)[i] !== "password" &&
          Object.keys(user)[i] !== "confirm_password"
        )
          error = validator(Object.keys(user)[i], user[Object.keys(user)[i]]);
      } else {
        error = validator(
          Object.keys(user)[i],
          user[Object.keys(user)[i]],
          Object.keys(user)[i] === "confirm_password" && user.password
        );
      }
      if (error) {
        empty = true;
      }
      setErrors((prev) => ({
        ...prev,
        [Object.keys(user)[i]]: error,
      }));
    }
    if (!empty) {
      try {
        const userData = { ...user };
        if (!!id && !user.password) {
          delete userData.password;
          delete userData.confirm_password;
        }

        let response;
        if (!!id) {
          response = await updateAdmin(id, userData);
        } else {
          response = await addUser(userData);
        }
        if (response && (response.status === 200 || response.status === 201)) {
          setLoading(false);
          toast.success(response?.data?.message || "Created ok.", {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
          });
          navigate("/admin-management");
        } else {
          setLoading(false);
          toast.error(`Something went wrong`, {
            theme: "colored",
          });
        }
      } catch (error) {
        setLoading(false);
        toast.error(`Something went wrong`, {
          theme: "colored",
        });
      }
    } else {
      return false;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {!!id ? "Edit" : "Add New Admin"}
            </MDTypography>
          </MDBox>
          {loading ? (
            <Loader />
          ) : (
            <Grid p={3} container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box component="form" autoComplete="off">
                  <TextField
                    fullWidth
                    margin="normal"
                    label="First Name"
                    name="first_name"
                    value={user.first_name}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: !!user.first_name }}
                  />
                  {errors.first_name && (
                    <>
                      <br />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.first_name}
                      </span>
                    </>
                  )}
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Last Name"
                    name="last_name"
                    value={user.last_name}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: !!user.last_name }}
                  />
                  {errors.last_name && (
                    <>
                      <br />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.last_name}
                      </span>
                    </>
                  )}
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: !!user.email }}
                  />
                  {errors.email && (
                    <>
                      <br />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.email}
                      </span>
                    </>
                  )}
                  {!id && (
                    <>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Password"
                        name="password"
                        type="password"
                        value={user.password}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: !!user.password }}
                      />
                      {errors.password && (
                        <>
                          <br />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors.password}
                          </span>
                        </>
                      )}
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Confirm Password"
                        name="confirm_password"
                        type="password"
                        value={user.confirm_password}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: !!user.confirm_password }}
                      />
                      {errors.confirm_password && (
                        <>
                          <br />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors.confirm_password}
                          </span>
                        </>
                      )}
                    </>
                  )}
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <MDButton color="info" onClick={handleSubmit}>
                      {!!id ? "Update " : "Add Admin"}
                    </MDButton>
                    <MDButton
                      className="cancel_butn"
                      onClick={() => navigate("/admin-management")}
                    >
                      Cancel
                    </MDButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddAdmin;
