import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import topbanner from "../../assets/images/top-banner1.png";
import arrow from "../../assets/images/arrow.png";
import arrow1 from "../../assets/images/small-cross.png";
import arrow2 from "../../assets/images/big-cross.png";
import aboutimage from "../../assets/images/aboutus.png";
import abouticon1 from "../../assets/images/abouticon1.png";
import abouticon2 from "../../assets/images/abouticon2.png";
import abouticon3 from "../../assets/images/abouticon3.png";
import featureicon1 from "../../assets/images/feature1.png";
import featureicon2 from "../../assets/images/feature2.png";
import featureicon3 from "../../assets/images/feature3.png";
import appicon1 from "../../assets/images/app-icon.png";
import appicon2 from "../../assets/images/google-icon.png";
import appimage from "../../assets/images/app-mobile.png";
import NavBar from "components/Header";
import Footer from "components/Footer";

function Home() {
  const listItems = [
    {
      imgSrc: abouticon1,
      text: "Automates price monitoring for flights, hotels, and car rentals.",
    },
    {
      imgSrc: abouticon3,
      text: "Offers easy rebooking options to help users save money on their travel bookings.",
    },
    {
      imgSrc: abouticon2,
      text: "Provides a centralized hub for managing all travel reservations in one place.",
    },
  ];

  const featurelistItems = [
    {
      featureimgSrc: featureicon1,
      featureHeading: "Automated Price<br /> Tracking",
      featuretext:
        "Stay updated on price changes for flights, hotels, and car rentals with automatic daily checks. AIRebooker ensures you never miss an opportunity to save.",
    },
    {
      featureimgSrc: featureicon2,
      featureHeading: "Seamless <br />Rebooking",
      featuretext:
        "Receive instant notifications when a lower price is available. Easily rebook your reservations without the hassle of manual price monitoring.",
    },
    {
      featureimgSrc: featureicon3,
      featureHeading: "Centralized Travel Management",
      featuretext:
        "Keep all your reservations in one place. Forward your confirmation emails to AIRebooker, and the app will automatically log, track, and manage your bookings.",
    },
  ];

  const stepslistItems = [
    {
      stepsHeading: "Sign Up",
      stepstext:
        "Join our robust social media platform todayand be on your way.",
    },
    {
      stepsHeading: "Showcase",
      stepstext:
        "Join our robust social media platform todayand be on your way.",
    },
    {
      stepsHeading: "Share",
      stepstext:
        "Join our robust social media platform todayand be on your way.",
    },
  ];

  return (
    <div className="App">
    <NavBar/>
      <div className="hero-section">
        <Container className="no-padding">
          <div className="col-xxl-12 px-0 no-padding">
            <div className="row align-items-center m-0">
              <div className="col-sm-12 col-lg-6 pr-6 banner-content">
                <img src={arrow1} alt="arrow" className="top-arrow1" />
                <label className="lead mb-3">
                  Save on Travel with AIRebooker
                </label>
                <h1 className="display-5 fw-bold lh-1 mb-3">
                  Effortless Price Tracking & <span>Rebooking</span>
                </h1>
                <p className="lead">
                  Take control of your travel reservations and never miss a
                  price drop again.
                </p>
                {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 top-banner-btn">Know More</button>
                    </div> */}
                <img src={arrow2} alt="arrow" className="top-arrow2" />

                <img src={arrow} alt="arrow" className="top-arrow" />
              </div>

              <div className="col-sm-12 col-lg-6 no-padding">
                <img src={topbanner} />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="aboutus-section">
        <Container>
          <div className="col-xxl-12 px-0">
            <div className="row align-items-center m-0">
              <div className="col-sm-12 col-lg-6 about-image">
                <img src={aboutimage} />
              </div>

              <div className="col-sm-12 col-lg-6 p-5">
                <img src={arrow1} alt="arrow" className="top-arrow1" />
                <label className="mb-3">More About AIRebooker</label>
                <h2 className="display-5 fw-bold lh-1 mb-3">About Us</h2>
                <p className="lead">
                  At <b>AIRebooker</b>, we are dedicated to simplifying the
                  travel experience for frequent traveler.{" "}
                  <b>
                    Our innovative mobile app helps users automatically monitor
                    travel reservations, track price changes, and secure savings
                    through rebooking at lower rates.{" "}
                  </b>
                  By leveraging advanced technology and seamless integration
                  with major travel service providers, <b>AIRebooker</b>{" "}
                  empowers users to manage their travel plans effortlessly.
                </p>

                <ul className="list-group about-list">
                  {listItems.map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <img src={item.imgSrc} alt="icon" />
                      <p>{item.text}</p>
                    </li>
                  ))}
                </ul>

                {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-4 about-btn">
                        <button type="button" className="btn btn-primary btn-lg px-4 top-banner-btn">Get The App</button>
                    </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="feature-section">
        <Container>
          <div className="col-xxl-12 px-0">
            <div className="row align-items-center m-0">
              <div className="col-sm-12 col-lg-12 about-image">
                <label className="mb-3">Features</label>
                <h2 className="display-5 fw-bold lh-1 mb-3">
                  Track, Save, Rebook – Your <br></br>Travel, Simplified
                </h2>
                <p className="lead">
                  Manage your travel reservations and monitor price drops
                  effortlessly with AIRebooker.
                </p>
              </div>

              <div className="col-sm-12 col-lg-12 p-2 mt-5">
                <img src={arrow1} alt="arrow" className="top-arrow1" />
                <ul className="row">
                  {featurelistItems.map((item, index) => (
                    <div key={index} className="col-md-4 p-5">
                      <div className="feature-image-row">
                        <img src={item.featureimgSrc} alt="icon" />
                      </div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: item.featureHeading,
                        }}
                      />
                      <p>{item.featuretext}</p>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="app-section">
        <Container>
          <div className="col-xxl-12 px-0">
            <div className="row align-items-center m-0 app-bg">
              <div className="col-sm-12 col-lg-6 app-image">
                <img src={appimage} />
              </div>

              <div className="col-sm-12 col-lg-6"></div>

              <div className="col-sm-12 col-lg-6 p-5">
                <img src={arrow1} alt="arrow" className="top-arrow1" />
                <label className="mb-3">Download App</label>
                <h2 className="display-5 fw-bold lh-1 mb-3">
                  Get The App Soon!
                </h2>
                <p className="lead">
                  Stay tuned for the app official launch and be the first to
                  enjoy a smarter, more efficient way to travel. Sign up now to
                  get early access and exclusive updates!
                </p>

                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-4">
                  <a href="#">
                    <img src={appicon1} />
                  </a>
                  <a href="#">
                    <img src={appicon2} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="steps-section">
        <Container>
          <div className="col-xxl-12 px-0">
            <div className="row align-items-center m-0">
              <div className="col-sm-12 col-lg-12 about-image">
                <label className="mb-3">Features</label>
                <h2 className="display-5 fw-bold lh-1 mb-3">
                  All in One for Travellers
                </h2>
              </div>

              <div className="col-sm-12 col-lg-12 p-2 mt-3">
                <img src={arrow1} alt="arrow" className="top-arrow1" />
                <ul className="row steps-row">
                  {stepslistItems.map((item, index) => (
                    <div key={index} className="col-md-4 p-5">
                      <div key={index} className="steps-col">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.stepsHeading,
                          }}
                        />
                        <p>{item.stepstext}</p>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="newsletter-section">
        <Container>
          <div className="col-xxl-12 px-0">
            <div className="row align-items-center m-0 newsletter-bg">
              <div className="col-sm-12 col-lg-6 p-5">
                <label className="mb-3">Newsletter</label>
                <h2 className="display-5 fw-bold lh-1 mb-3">
                  Let’s stay in touch!
                </h2>
                <p className="lead">
                  We’ll sand you a nice letter once per week. No Spam.
                </p>
              </div>

              <div className="col-sm-12 col-lg-6 newsletter-form">
                <Form inline>
                  <Row>
                    <div className="d-flex col-sm-12 col-lg-12">
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Email"
                        className=" mr-sm-2"
                      />
                      <Button type="submit">Subscribe</Button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
