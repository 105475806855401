import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import airlinrlogo from "assets/img/airlinrlogo.png";
import plane from "assets/img/plane.svg";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
function PastFlightBooking({ flights, loading, allBookingData }) {
  const navigate = useNavigate();
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");

  const REASON_CHOICES = [
    ["INCOMPLETE_RECORDS", "Incomplete Records"],
    ["TECHNICAL_ISSUES", "Technical Issues"],
    ["POLICY_RESTRICTIONS", "Policy Restrictions"],
    ["HUMAN_ERROR", "Human Error"],
    ["NOT_RELEVANT", "Not Relevant"],
    ["OTHER", "Other"],
  ];

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
    } else {
      updateSubstituteStatus(show, {
        status: status,
        additional_information: selectedReason,
      })
        .then((res) => {
          console.log("status response", res);
          if (res.status == 200) {
            allBookingData();
            toast.success(
              `Status ${status == "ELIMINATE"
                ? `${status?.toLowerCase()}d`
                : `${status?.toLowerCase()}ed`
              } successfully`,
              {
                theme: "colored",
              }
            );
            setShow(false);
            setSelectedReason("");
            setSelectMesage("");
          } else {
            toast.error(`Failed to ${status?.toLowerCase()} status`, {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`, {
      state: { passBooking: "pass_booking", }
    });
  };

  return (
    <MDBox py={3}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {flights?.length > 0 ?
            flights?.map((item, i) => (
              <Accordion className="mb-3" defaultExpanded={i === 0} key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="airline_header row">
                    <div className="airline_data col-md-4">
                      <img src={AirlineLogo} alt="airline logo" />
                      <div className="airline_title_section">
                        <MDTypography
                          variant="h6"
                          color="white"
                          className="airline_heading"
                        >
                          {item?.airline}
                        </MDTypography>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          {item.return_date ? "Round Trip" : "Oneway Ticket"}
                        </MDTypography>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <MDTypography
                        variant="h5"
                        color="white"
                        className="flight_details"
                      >
                        {item?.price}
                      </MDTypography>
                    </div>
                    <div className="ticket_detail_heading col-md-5">
                      <MDTypography variant="h6" color="white">
                        {item?.departure_city?.split(",")[0]}-to-
                        {item?.destination_city?.split(",")[0]}
                      </MDTypography>
                      <div className="ticket_details">
                        <div>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {item?.departure_date} {item?.departure_time}
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {item?.return_date &&
                              item?.return_date + " " + item?.Arrives_time}
                          </MDTypography>
                        </div>

                        <span>|</span>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          1 Adult
                        </MDTypography>
                        <span>|</span>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          {item?.flight_class}
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="substitues_section">
                  <div className="">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>
                      {item?.substitutes && item?.substitutes?.length > 0 ? (
                        <button
                          className="view_all"
                          onClick={() => redirection(item?.id)}
                        >
                          View All
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {loading ? (
                      <>
                        <MDTypography
                          className="text-center"
                          variant="h6"
                          color="white"
                        >
                          Loading...
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {item?.substitutes && item?.substitutes?.length > 0 ? (
                          item?.substitutes
                            ?.slice(0, 3)
                            ?.map((ticket, index) => (
                              <div
                                key={index}
                                className="container-fluid p-3 mb-3 subtiktmain"
                              >
                                <div
                                  className="row align-items-center"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className={`col-md-5
                                      d-flex airlinetkt`}
                                  >
                                    <div
                                      className="col-md-6 d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <img
                                        src={airlinrlogo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <div className="ticktnam mx-2">
                                        <p className="ticktptag12">
                                          {ticket.airline_name}
                                        </p>
                                        <p className="flight_class_name">
                                          {item?.flight_class}
                                        </p>
                                        <p
                                          className="ticktptag21"
                                          style={{ fontSize: "13px" }}
                                        >
                                          <span
                                            style={{
                                              color: "#61a108",
                                              fontWeight: "bold",
                                              fontSize: "13px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {ticket.match_percentage}%
                                          </span>
                                          &nbsp;Matched
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-6 "
                                      style={{ marginTop: "20px" }}
                                    >
                                      <p className="fstptag">{ticket?.price}</p>
                                      <p
                                        className="sndptag"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        You Can Save{" "}
                                        <span
                                          style={{
                                            color: "#61a108",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {ticket?.saved_amount}
                                          {/* $ {getSaving(item?.price, ticket.price)} */}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={`col-md-5 Dtime`}>
                                    <div className="Dtimelist">
                                      <ul className="ul-list">
                                        <p className="ullist">
                                          {
                                            ticket?.departure_time?.split(
                                              " "
                                            )?.[0]
                                          }
                                          <span className="depature_time">
                                            {`${ticket?.departure_time?.split(
                                              " "
                                            )?.[1]
                                              } ${ticket?.departure_time?.split(
                                                " "
                                              )?.[2]
                                              }`}
                                          </span>
                                        </p>

                                        <p className="depature_code">
                                          {ticket.departure_iata_code}
                                        </p>
                                      </ul>
                                    </div>
                                    <div className="Dtimelist">
                                      <ul style={{ padding: "0" }}>
                                        <img
                                          src={plane}
                                          alt="Custom Plane"
                                          width="68px"
                                          style={{ marginTop: "20px" }}
                                        />
                                        <li className="ullist">
                                          {getTime(
                                            ticket?.departure_time,
                                            ticket?.arrival_time
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="Dtimelist">
                                      <ul className="ul-list">
                                        <p className="ullist">
                                          {
                                            ticket?.arrival_time?.split(
                                              " "
                                            )?.[0]
                                          }
                                          <span className="depature_time">
                                            {`${ticket?.arrival_time?.split(
                                              " "
                                            )?.[1]
                                              } ${ticket?.arrival_time?.split(
                                                " "
                                              )?.[2]
                                              }`}
                                          </span>
                                        </p>
                                        <p className="depature_code">
                                          {ticket.arrival_iata_code}
                                        </p>
                                      </ul>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-8">
                                <div className="listmn" style={{ marginTop: "15px" }}>
                                  <p className="listptag">Orlando-to-Los Angeles</p>
                                  <ol className="liststle">
                                    <li>28 Sep</li>
                                    <li>1 Adult</li>
                                    <li>Economy</li>
                                  </ol>
                                </div>
                              </div> */}

                                  <div className="col-md-2 d-flex justify-content-end  airlinetkt">
                                    <div className="col-md-12 text-end">
                                      <div className="ticket_detail_butns">
                                        <MDButton
                                          className={`past_status_butn ${ticket?.status == "ACCEPT" &&
                                            "accepted_butn"
                                            }
                                              ${ticket?.status == "REJECT" &&
                                            "rejected_butn"
                                            }
                                              ${ticket?.status == "ELIMINATE" &&
                                            "eliminated_butn"
                                            }
                                              ${ticket?.status == "PENDING" &&
                                            "accept_butn"
                                            }`}
                                        >
                                          {ticket?.status == "ACCEPT" &&
                                            "ACCEPTED"}
                                          {ticket?.status == "REJECT" &&
                                            "REJECTED"}
                                          {ticket?.status == "ELIMINATE" &&
                                            "ELIMINATED"}
                                          {ticket?.status == "PENDING" &&
                                            "PENDING"}
                                        </MDButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <ul className="row mt-2">
                                    {ticket.percentage_changes
                                      ?.split(",")
                                      .map((value) => {
                                        if (value !== "") {
                                          return (
                                            <li
                                              className="col-md-12 hotel_desc_list"
                                              key={index}
                                            >
                                              {value}
                                            </li>
                                          );
                                        }
                                      })}
                                  </ul>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p className="no_substitute">
                            No substitute available
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
            :
            <>
              <div className="text-center mt-3">
                <MDTypography variant="h5">No Substitute Found</MDTypography>
              </div>
            </>
          }
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status == "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status == "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Reject this offer.
              </MDTypography>
            }

            {status == "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" && (
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">
                    Select Reason
                  </InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default PastFlightBooking;
