import { useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Visibility from "@mui/icons-material/Visibility"; // Eye open
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Icon from "@mui/material/Icon";
// Images
import Logo from "assets/images/auth/logo.png";
import { IconButton } from "@mui/material";
import { setNewPassword } from "utils/api";
import MDAlert from "components/MDAlert";
import validator from "utils/validate";

function NewPassword() {
  const navigate = useNavigate();
  const { userId, token } = useParams();

  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confPassword, setConfPassword] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    new_password: "",
    confirm_password: "",
  });

  const validate = (name, value) => {
    let error = "";
    error = validator(name, value, name == "confirm_password" && formData.new_password)
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleFormdata = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate onChange
    validate(name, value);
  };

  const handleConfirm = () => {
    validate("new_password", formData.new_password);
    validate("confirm_password", formData.confirm_password);

    if (
      !errors.new_password &&
      !errors.confirm_password &&
      formData.new_password &&
      formData.confirm_password
    ) {
      // If no errors, proceed with navigation
      // navigate("/login");
      setNewPassword({ userId, token, formData })
        .then((res) => {
          if (res.status == 200) {
            setShowAlert(res.data.message);
            setTimeout(() => {
              setShowAlert(false);
              navigate("/login");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Validation errors", errors);
    }
  };

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {showAlert}
      </MDTypography>
    </MDTypography>
  );



  const handleToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleConfToggle = () => {
    setConfPassword(!confPassword);
  };
  return (
    <BasicLayout>
      <div style={{ position: "absolute", right: "25px", top: "20px" }}>
        {showAlert && (
          <MDAlert color="info" dismissible>
            {alertContent("info")}
          </MDAlert>
        )}
      </div>
      <div className="main_file">
        <div className="bg_login1">
          <div className="bg_image_login">
            <img src={Logo} />
          </div>
        </div>
        <div className="login_form">
          <div>
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="#3D4B7C"
              mt={1}
            >
              New Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="blue" my={1}>
              Please enter your new password.
            </MDTypography>
            <div>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="new_password"
                value={formData?.new_password}
                onChange={(e) => {
                  handleFormdata(e);
                }}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={handleToggle}
                style={{ marginLeft: "-40px" }}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.new_password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.new_password}
                  </span>
                </>
              )}
              <br />
              <input
                type={confPassword ? "text" : "password"}
                placeholder="Confirm Password"
                name="confirm_password"
                value={formData?.confirm_password}
                onChange={(e) => {
                  handleFormdata(e);
                }}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={handleConfToggle}
                style={{ marginLeft: "-40px" }}
              >
                {!confPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.confirm_password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.confirm_password}
                  </span>
                </>
              )}
            </div>
            <div className="sign_butn">
              <MDButton
                onClick={() => {
                  handleConfirm();
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                Confirm
              </MDButton>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}

export default NewPassword;
