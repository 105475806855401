import React, { useState } from "react";
import "./FAQ.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState(true);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setActiveTab(false);
  };

  const defalutTab = () => {
    setActiveTab(!activeTab);
    setActiveIndex(null)
  };

  const faqData = [
    {
      question:
        "Reference site about Lorem Ipsum, giving information on its origins, as well as?",
      answer: `Reference site about Lorem Ipsum, giving information on its origins, 
                as well as a random Lipsum generator.`,
    },
    {
      question: "Reference site about Lorem Ipsum, giving?",
      answer: `Reference site about Lorem Ipsum, giving information on its origins, 
                as well as a random Lipsum generator.`,
    },
    {
      question:
        "Reference site about Lorem Ipsum, giving information on its origins, as well as?",
      answer: `Reference site about Lorem Ipsum, giving information on its origins, 
                as well as a random Lipsum generator.`,
    },
    // Add more FAQ questions as needed
  ];

  return (
    <div className="faq-container">
      <h5 className="faq-title">FAQ's</h5>
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <div className="faqRef">
        <div className="faq-question" onClick={() => defalutTab(false)}>
          <h3>
            Reference site about Lorem Ipsum, giving information on its origins,
            as well as?
          </h3>
          {activeTab ? <FaChevronUp style={{cursor:"pointer"}}/> : <FaChevronDown style={{cursor:"pointer"}}/>}
        </div>
        {activeTab && (
          <div>
            <p className="faq2">
              Reference site about Lorem Ipsum, giving information on its
              origins, as well as a random Lipsum generator.Reference site about
              Lorem Ipsum, giving information on its origins, as well as a
              random Lipsum generator. Reference site about Lorem Ipsum, giving
              information on its origins, as well as a random Lipsum
              generator.Reference site about Lorem Ipsum, giving information on
              its origins, as well as a random Lipsum generator.Reference site
              about Lorem Ipsum, giving information on its origins, as well as a
              random Lipsum generator. Lipsum generator.Reference site about
              Lorem Ipsum, giving information on its origins, as well as a
              random Lipsum generator.
            </p>
          </div>
        )}
      </div>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <h3>{faq.question}</h3>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p className="faq2">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
