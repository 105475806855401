import React, { useState } from "react";
import "./SubstituteTicket.css";
import "bootstrap/dist/css/bootstrap.min.css";
import airlinrlogo from "assets/img/airlinrlogo.png";
import MDTypography from "components/MDTypography";
import plane from "assets/img/plane.svg";
import MDButton from "components/MDButton";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { Modal } from "react-bootstrap";

const SubstituteTicket = ({
  substitutes,
  loading,
  fetchAllSubstitute,
  passBooking,
  details,
  same_flight_sub
}) => {
  const [ticketStatuses, setTicketStatuses] = React.useState({});

  console.log("same_flight_subsame_flight_sub", same_flight_sub)

  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("")
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);
  const [pageData, setPageData] = useState(substitutes);
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const handleAcceptSite = () => {
    window.open(siteUrl, '_blank')
    setShow(false)
  }

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleStatus = (id, status, url) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
      setSelectMesage("Please select any reason");
    } else {
      updateSubstituteStatus(show, {
        status: status == "REINSTATE" ? "ACCEPT" : status,
        additional_information: selectedReason,
        other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success(`Status ${status?.toLowerCase()}ed successfully`, {
              theme: "colored",
            });
            fetchAllSubstitute();
          } else {
            toast.error(`Failed to ${status?.toLowerCase()} status`, {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  return (
    <>
      <MDTypography variant="p" className="p-title">
        Substitute Tickets
      </MDTypography>
      {loading ? (
        <>
          <h5>Loading ..</h5>
        </>
      ) : (
        <>
          <div
            className="container-fluid p-3 mb-3 subtiktmain"
          >
            {
              same_flight_sub?.return_offer ?
                <>
                  <div className="row align-items-center">
                    <div className="col-md-9">
                      <div
                        className="row align-items-center"
                        style={{ alignItems: "center" }}
                      >
                        <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                        >
                          <div className="row align-items-center"
                            style={{
                              width: "100%",
                              marginRight: "0px",
                            }}>

                            <div
                              className="col-md-8 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <img
                                src={airlinrlogo}
                                alt=""
                                className="img-fluid"
                              />
                              <div className="ticktnam mx-2">
                                <p className="ticktptag12">
                                  {same_flight_sub?.outbound_offer?.airline_name}
                                </p>
                                <p className="flight_class_name">
                                  {same_flight_sub?.outbound_offer?.travel_class}
                                </p>
                                <p className="flight_class_name">
                                  {`${same_flight_sub?.outbound_offer?.airline_code}${same_flight_sub?.outbound_offer?.flight_number}`}
                                </p>
                                <p
                                  className="ticktptag21"
                                  style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                >
                                  <span
                                    style={{
                                      color: "#61a108",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {same_flight_sub?.outbound_offer.match_percentage}%
                                  </span>
                                  &nbsp;Matched
                                </p>
                                {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                  {same_flight_sub?.outbound_offer.percentage_changes}%
                                                </p> */}
                              </div>


                            </div>
                            <div
                              className="col-md-4"
                            >
                              <p className="depature-heading">Depature</p>
                              <p className="depature_code d-flex justify-content-center">    ${same_flight_sub?.outbound_offer?.price}</p>
                              {/* <p
                                              className="sndptag mb-0"
                                              style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                            >
                                              You Can Save{" "}
                                              <span
                                                style={{
                                                  color: "#61a108",
                                                  fontWeight: "bold",
                                                  fontSize: "13px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {same_flight_sub?.outbound_offer?.saved_amount}

                                              </span>
                                            </p> */}

                            </div>
                          </div>
                        </div>
                        <div className={`col-md-6 Dtime`}>

                          <div className="Dtimelist">

                            <ul className="ul-list">


                              <p className="ullist">
                                {
                                  same_flight_sub?.outbound_offer?.departure_date?.split(
                                    "T"
                                  )?.[0]
                                }
                                <span className="depature_time">
                                  {`${same_flight_sub?.outbound_offer?.departure_time?.split(
                                    ":"
                                  )?.[0]
                                    }:${same_flight_sub?.outbound_offer?.departure_time?.split(
                                      ":"
                                    )?.[1]
                                    }`}
                                </span>
                              </p>

                              <p className="depature_code">
                                {same_flight_sub?.outbound_offer?.departure_iata_code}
                              </p>

                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul style={{ padding: "0" }}>
                              <img
                                src={plane}
                                alt="Custom Plane"
                                width="68px"
                                style={{ marginTop: "20px" }}
                              />
                              <li className="ullist">
                                {/* {getTime(
                                  same_flight_sub?.outbound_offer?.departure_time,
                                  same_flight_sub?.outbound_offer?.arrival_time
                                )} */}
                              </li>
                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul className="ul-list">
                              <p className="ullist">
                                {
                                  same_flight_sub?.outbound_offer?.arrival_date?.split(
                                    "T"
                                  )?.[0]
                                }
                                <span className="depature_time">
                                  {`${same_flight_sub?.outbound_offer?.arrival_time?.split(
                                    ":"
                                  )?.[0]
                                    }:${same_flight_sub?.outbound_offer?.arrival_time?.split(
                                      ":"
                                    )?.[1]
                                    }`}
                                </span>
                              </p>

                              <p className="depature_code">
                                {same_flight_sub?.outbound_offer?.arrival_iata_code}
                              </p>

                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="px-5">
                        <ul className="row mt-2">
                          {same_flight_sub?.outbound_offer?.percentage_changes?.split(",")?.map((value, index) => {
                            if (value !== "") {
                              return (
                                <li
                                  className="col-md-12 hotel_desc_list"
                                  key={index}
                                >
                                  {value}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                      <div
                        className="row align-items-center"
                        style={{ alignItems: "center" }}
                      >
                        <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                        >
                          <div className="row d-flex align-items-center"
                            style={{
                              width: "100%",
                              marginRight: "0px",
                            }}>
                            <div
                              className="col-md-8 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <img
                                src={airlinrlogo}
                                alt=""
                                className="img-fluid"
                              />
                              <div className="ticktnam mx-2">
                                <p className="ticktptag12">
                                  {same_flight_sub?.return_offer?.airline_name}
                                </p>
                                <p className="flight_class_name">
                                  {same_flight_sub?.return_offer?.travel_class}
                                </p>
                                <p className="flight_class_name">
                                  {`${same_flight_sub?.return_offer?.airline_code}${same_flight_sub?.return_offer?.flight_number}`}
                                </p>
                                <p
                                  className="ticktptag21"
                                  style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                >
                                  <span
                                    style={{
                                      color: "#61a108",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {same_flight_sub?.return_offer?.match_percentage}%
                                  </span>
                                  &nbsp;Matched
                                </p>
                                {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                  {same_flight_sub?.return_offer?.percentage_changes}%
                                                </p> */}
                              </div>


                            </div>
                            <div
                              className="col-md-4 text-end"
                            >
                              <p className="depature-heading">Return</p>
                              <p className="depature_code d-flex justify-content-center"> {`${same_flight_sub?.return_offer && `$${same_flight_sub?.return_offer?.price}`}`}</p>
                              {/* <p
                                              className="sndptag mb-0"
                                              style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                            >
                                              You Can Save{" "}
                                              <span
                                                style={{
                                                  color: "#61a108",
                                                  fontWeight: "bold",
                                                  fontSize: "13px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {same_flight_sub?.return_offer?.saved_amount}

                                              </span>
                                            </p> */}
                            </div>
                          </div>
                        </div>
                        <div className={`col-md-6 Dtime`}>
                          <div className="Dtimelist">
                            <ul className="ul-list">
                              <p className="ullist">
                                {same_flight_sub?.return_offer?.departure_date?.split("T")?.[0]}
                                <span className="depature_time">
                                  {`${same_flight_sub?.return_offer?.departure_time?.split(":")?.[0]}:${same_flight_sub?.return_offer?.departure_time?.split(":")?.[1]}`}
                                </span>
                              </p>
                              <p className="depature_code">
                                {same_flight_sub?.return_offer?.departure_iata_code}
                              </p>
                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul style={{ padding: "0" }}>
                              <img
                                src={plane}
                                alt="Custom Plane"
                                width="68px"
                                style={{ marginTop: "20px" }}
                              />
                              {/* <li className="ullist">
                                {getTime(
                                  same_flight_sub?.return_offer?.departure_time,
                                  same_flight_sub?.return_offer?.arrival_time
                                )}
                              </li> */}
                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul className="ul-list">
                              <p className="ullist">
                                {same_flight_sub?.return_offer?.arrival_date?.split("T")?.[0]}
                                <span className="depature_time">
                                  {`${same_flight_sub?.return_offer?.arrival_time?.split(":")?.[0]}:${same_flight_sub?.return_offer?.arrival_time?.split(":")?.[1]}`}
                                </span>
                              </p>
                              <p className="depature_code">
                                {same_flight_sub?.return_offer?.arrival_iata_code}
                              </p>
                            </ul>
                          </div>

                        </div>
                      </div>
                      <div className="px-5">
                        <ul className="row mt-2">
                          {same_flight_sub?.return_offer?.percentage_changes?.split(",")?.map((value, index) => {
                            if (value !== "") {
                              return (
                                <li
                                  className="col-md-12 hotel_desc_list"
                                  key={index}
                                >
                                  {value}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>


                    <div className="col-md-3">
                      {same_flight_sub?.outbound_offer?.status == "PENDING" ? (
                        <div className="text-center">
                          <div>
                            <p className="fstptag"> Total Price</p>
                            <p className="fstptag"> {same_flight_sub?.total_price}</p>
                            {/* <p
                                              className="sndptag"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              You Can Save{" "}
                                              <span
                                                style={{
                                                  color: "#61a108",
                                                  fontWeight: "bold",
                                                  fontSize: "13px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {same_flight_sub?.outbound_offer?.saved_amount}

                                              </span>
                                            </p> */}
                          </div>
                          <div className="ticket_detail_butns flex-column">
                            <MDButton
                              className="accept_butn"
                              onClick={() =>
                                handleStatus(same_flight_sub?.outbound_offer?.id, "ACCEPT", same_flight_sub?.outbound_offer?.airline_website)
                              }
                              disabled={
                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                              }
                            >
                              ACCEPT
                            </MDButton>
                            <MDButton
                              className={`reject_butn my-1`}
                              onClick={() =>
                                handleStatus(same_flight_sub?.outbound_offer?.id, "REJECT")
                              }
                              disabled={
                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                              }
                            >
                              REJECT
                            </MDButton>
                            {/* <MDButton
                                              className="eliminate_butn"
                                              onClick={() =>
                                                handleStatus(
                                                  same_flight_sub?.outbound_offer?.id,
                                                  "ELIMINATE"
                                                )
                                              }
                                              disabled={
                                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                                              }
                                            >
                                              ELIMINATE
                                            </MDButton> */}
                          </div>

                        </div>
                      ) : (
                        <>
                          <div className="d-flex justify-content-end  airlinetkt ">
                            <div className="col-md-12 text-center">
                              <div>
                                <p className="fstptag"> Total Price</p>
                                <p className="fstptag"> {same_flight_sub?.total_price}</p>
                                {/* <p
                                                  className="sndptag"
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                  You Can Save{" "}
                                                  <span
                                                    style={{
                                                      color: "#61a108",
                                                      fontWeight: "bold",
                                                      fontSize: "13px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {same_flight_sub?.outbound_offer?.saved_amount}

                                                  </span>
                                                </p> */}
                              </div>
                              <div className="ticket_detail_butns flex-column"
                              // style={{
                              //   position: 'relative',
                              //   right: '0',
                              //   top: '67px'
                              // }}
                              >
                                <MDButton
                                  className={`accept_butn ${same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                    "accepted_butn"
                                    }`}
                                  disabled={
                                    same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                  }
                                  onClick={() =>
                                    handleStatus(
                                      same_flight_sub?.outbound_offer?.id,
                                      "ACCEPT"
                                    )
                                  }
                                >
                                  {same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                    ? "ACCEPTED"
                                    : "ACCEPT"}
                                </MDButton>
                                <MDButton
                                  className={`my-1 ${same_flight_sub?.outbound_offer?.status == "REJECT"
                                    ? "rejected_butn"
                                    : "reject_butn"
                                    }`}
                                  disabled={
                                    same_flight_sub?.outbound_offer?.status == "REJECT"
                                  }
                                  onClick={() =>
                                    handleStatus(
                                      same_flight_sub?.outbound_offer?.id,
                                      "REJECT"
                                    )
                                  }
                                >
                                  {same_flight_sub?.outbound_offer?.status == "REJECT"
                                    ? "REJECTED"
                                    : "REJECT"}
                                </MDButton>

                                {/* <MDButton
                                                  className={`${same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                    ? "eliminated_butn"
                                                    : "eliminate_butn"
                                                    }`}
                                                  disabled={
                                                    same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                  }
                                                  onClick={() =>
                                                    handleStatus(
                                                      same_flight_sub?.outbound_offer?.id,
                                                      "ELIMINATE"
                                                    )
                                                  }
                                                >
                                                  {same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                    ? "ELIMINATED"
                                                    : "ELIMINATE"}
                                                </MDButton> */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
                :
                <>
                  <div
                    className="row align-items-center"
                    style={{ alignItems: "center" }}
                  >
                    <div className={`col-md-4
                                      d-flex airlinetkt align-items-center`}
                    >
                      <div
                        className="col-md-8 d-flex"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          src={airlinrlogo}
                          alt=""
                          className="img-fluid"
                        />
                        <div className="ticktnam mx-2">
                          <p className="ticktptag12">
                            {same_flight_sub?.outbound_offer?.airline_name}
                          </p>
                          <p className="flight_class_name">
                            {same_flight_sub?.outbound_offer?.travel_class}
                          </p>
                          <p className="flight_class_name">
                            {`${same_flight_sub?.outbound_offer?.airline_code}${same_flight_sub?.outbound_offer?.flight_number}`}
                          </p>
                          <p
                            className="ticktptag21"
                            style={{ fontSize: "13px", marginBottom: "0px !important" }}
                          >
                            <span
                              style={{
                                color: "#61a108",
                                fontWeight: "bold",
                                fontSize: "13px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {same_flight_sub?.outbound_offer?.match_percentage}%
                            </span>
                            &nbsp;Matched
                          </p>
                          {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                {ticket.outbound_offer.percentage_changes}%
                                              </p> */}
                        </div>


                      </div>
                      <div
                        className="col-md-4"
                      >
                        <p className="depature_code d-flex justify-content-center">    ${same_flight_sub?.outbound_offer?.price}</p>

                      </div>

                    </div>

                    <div className={`col-md-4 Dtime`}>

                      <div className="Dtimelist">

                        <ul className="ul-list">


                          <p className="ullist">
                            {
                              same_flight_sub?.outbound_offer?.departure_date?.split(
                                "T"
                              )?.[0]
                            }
                            <span className="depature_time">
                              {`${same_flight_sub?.outbound_offer?.departure_time?.split(
                                ":"
                              )?.[0]
                                }:${same_flight_sub?.outbound_offer?.departure_time?.split(
                                  ":"
                                )?.[1]
                                }`}
                            </span>
                          </p>

                          <p className="depature_code">
                            {same_flight_sub?.outbound_offer?.departure_iata_code}
                          </p>

                        </ul>
                      </div>
                      <div className="Dtimelist">
                        <ul style={{ padding: "0" }}>
                          <img
                            src={plane}
                            alt="Custom Plane"
                            width="68px"
                            style={{ marginTop: "20px" }}
                          />
                          {/* <li className="ullist">
                            {getTime(
                              same_flight_sub?.outbound_offer?.departure_time,
                              same_flight_sub?.outbound_offer?.arrival_time
                            )}
                          </li> */}
                        </ul>
                      </div>
                      <div className="Dtimelist">
                        <ul className="ul-list">
                          <p className="ullist">
                            {
                              same_flight_sub?.outbound_offer?.arrival_date?.split(
                                "T"
                              )?.[0]
                            }
                            <span className="depature_time">
                              {`${same_flight_sub?.outbound_offer?.arrival_time?.split(
                                ":"
                              )?.[0]
                                }:${same_flight_sub?.outbound_offer?.arrival_time?.split(
                                  ":"
                                )?.[1]
                                }`}
                            </span>
                          </p>

                          <p className="depature_code">
                            {same_flight_sub?.outbound_offer?.arrival_iata_code}
                          </p>

                        </ul>
                      </div>
                    </div>


                    {same_flight_sub?.outbound_offer?.status == "PENDING" ? (
                      <div className="col-md-4 d-flex airlinetkt">
                        <div className="col-md-12">
                          <div className="ticket_detail_butns">
                            <MDButton
                              className="accept_butn"
                              onClick={() =>
                                handleStatus(same_flight_sub?.outbound_offer?.id, "ACCEPT", same_flight_sub?.outbound_offer?.airline_website)
                              }
                              disabled={
                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                              }
                            >
                              ACCEPT
                            </MDButton>
                            <MDButton
                              className={`reject_butn
                                            `}
                              onClick={() =>
                                handleStatus(same_flight_sub?.outbound_offer?.id, "REJECT")
                              }
                              disabled={
                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                              }
                            >
                              REJECT
                            </MDButton>
                            {/* <MDButton
                              className="eliminate_butn"
                              onClick={() =>
                                handleStatus(
                                  same_flight_sub?.outbound_offer?.id,
                                  "ELIMINATE"
                                )
                              }
                              disabled={
                                ticketStatuses[same_flight_sub?.outbound_offer?.id] ||
                                same_flight_sub?.outbound_offer?.status !== "PENDING"
                              }
                            >
                              ELIMINATE
                            </MDButton> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-md-4 d-flex justify-content-end  airlinetkt ">
                          <div className="col-md-12 text-end">
                            <div className="ticket_detail_butns"
                            // style={{
                            //   position: 'relative',
                            //   right: '0',
                            //   top: '67px'
                            // }}
                            >
                              {
                                same_flight_sub?.outbound_offer?.status == "ELIMINATE" ?
                                  <MDButton
                                    className={`accept_butn ${same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                      "accepted_butn"
                                      }`}
                                    disabled={
                                      same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                    }
                                    onClick={() =>
                                      handleStatus(
                                        same_flight_sub?.outbound_offer?.id,
                                        "REINSTATE"
                                      )
                                    }
                                  >
                                    {same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                      ? "ACCEPTED"
                                      : "ACCEPT"}
                                  </MDButton>
                                  :
                                  <MDButton
                                    className={`accept_butn ${same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                      "accepted_butn"
                                      }`}
                                    disabled={
                                      same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                    }
                                    onClick={() =>
                                      handleStatus(
                                        same_flight_sub?.outbound_offer?.id,
                                        "ACCEPT", same_flight_sub?.outbound_offer?.airline_website
                                      )
                                    }
                                  >
                                    {same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                      ? "ACCEPTED"
                                      : "ACCEPT"}
                                  </MDButton>

                              }
                              <MDButton
                                className={`${same_flight_sub?.outbound_offer?.status == "REJECT"
                                  ? "rejected_butn"
                                  : "reject_butn"
                                  }`}
                                disabled={
                                  same_flight_sub?.outbound_offer?.status == "REJECT"
                                }
                                onClick={() =>
                                  handleStatus(
                                    same_flight_sub?.outbound_offer?.id,
                                    "REJECT"
                                  )
                                }
                              >
                                {same_flight_sub?.outbound_offer?.status == "REJECT"
                                  ? "REJECTED"
                                  : "REJECT"}
                              </MDButton>

                              {/* <MDButton
                                className={`${same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                  ? "eliminated_butn"
                                  : "eliminate_butn"
                                  }`}
                                disabled={
                                  same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                }
                                onClick={() =>
                                  handleStatus(
                                    same_flight_sub?.outbound_offer?.id,
                                    "ELIMINATE"
                                  )
                                }
                              >
                                {same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                  ? "ELIMINATED"
                                  : "ELIMINATE"}
                              </MDButton> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="px-5">
                    <ul className="row mt-2">
                      {same_flight_sub?.outbound_offer?.percentage_changes?.split(",")?.map((value, index) => {
                        if (value !== "") {
                          return (
                            <li
                              className="col-md-12 hotel_desc_list"
                              key={index}
                            >
                              {value}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </>
            }
          </div>
          {
            displayedItems?.length > 0 &&
            displayedItems?.map((ticket, index) => (
              <div
                key={index}
                className="container-fluid p-3 mb-3 subtiktmain"
              >
                {
                  ticket?.return_offer ?
                    <>
                      <div className="row align-items-center">
                        <div className="col-md-9">
                          <div
                            className="row align-items-center"
                            style={{ alignItems: "center" }}
                          >
                            <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                            >
                              <div className="row align-items-center"
                                style={{
                                  width: "100%",
                                  marginRight: "0px",
                                }}
                              >
                                <div
                                  className="col-md-8 d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <img
                                    src={airlinrlogo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="ticktnam mx-2">
                                    <p className="ticktptag12">
                                      {ticket.outbound_offer?.airline_name}
                                    </p>
                                    <p className="flight_class_name">
                                      {details?.[0]?.flight_class}
                                    </p>
                                    <p className="flight_class_name">
                                      {`${ticket?.outbound_offer?.airline_code}${ticket?.outbound_offer?.flight_number}`}
                                    </p>
                                    <p
                                      className="ticktptag21"
                                      style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                    >
                                      <span
                                        style={{
                                          color: "#61a108",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {ticket.outbound_offer.match_percentage}%
                                      </span>
                                      &nbsp;Matched
                                    </p>
                                    {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                      {ticket.outbound_offer.percentage_changes}%
                                    </p> */}
                                  </div>


                                </div>
                                <div
                                  className="col-md-4"
                                >
                                  <p className="depature-heading">Depature</p>
                                  <p className="depature_code d-flex justify-content-center">    ${ticket.outbound_offer?.price}</p>
                                  <p
                                    className="sndptag mb-0"
                                    style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                  >
                                    You Can Save{" "}
                                    <span
                                      style={{
                                        color: "#61a108",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {ticket?.outbound_offer?.saved_amount}

                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-6 Dtime`}>

                              <div className="Dtimelist">

                                <ul className="ul-list">


                                  <p className="ullist">
                                    {
                                      ticket?.outbound_offer?.departure_time?.split(
                                        " "
                                      )?.[0]
                                    }
                                    <span className="depature_time">
                                      {`${ticket?.outbound_offer?.departure_time?.split(
                                        " "
                                      )?.[1]
                                        } ${ticket?.outbound_offer?.departure_time?.split(
                                          " "
                                        )?.[2]
                                        }`}
                                    </span>
                                  </p>

                                  <p className="depature_code">
                                    {ticket.outbound_offer?.departure_iata_code}
                                  </p>

                                </ul>
                              </div>
                              <div className="Dtimelist">
                                <ul style={{ padding: "0" }}>
                                  <img
                                    src={plane}
                                    alt="Custom Plane"
                                    width="68px"
                                    style={{ marginTop: "20px" }}
                                  />
                                  <li className="ullist">
                                    {getTime(
                                      ticket?.outbound_offer?.departure_time,
                                      ticket?.outbound_offer?.arrival_time
                                    )}
                                  </li>
                                </ul>
                              </div>
                              <div className="Dtimelist">
                                <ul className="ul-list">
                                  <p className="ullist">
                                    {
                                      ticket?.outbound_offer?.arrival_time?.split(
                                        " "
                                      )?.[0]
                                    }
                                    <span className="depature_time">
                                      {`${ticket?.outbound_offer?.arrival_time?.split(
                                        " "
                                      )?.[1]
                                        } ${ticket?.outbound_offer?.arrival_time?.split(
                                          " "
                                        )?.[2]
                                        }`}
                                    </span>
                                  </p>

                                  <p className="depature_code">
                                    {ticket.outbound_offer?.arrival_iata_code}
                                  </p>

                                </ul>
                              </div>
                            </div>

                          </div>
                          <div className="px-5">
                            <ul className="row mt-2">
                              {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                                if (value !== "") {
                                  return (
                                    <li
                                      className="col-md-12 hotel_desc_list"
                                      key={index}
                                    >
                                      {value}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                          <div
                            className="row align-items-center"
                            style={{ alignItems: "center" }}
                          >
                            <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                            >
                              <div className="row d-flex align-items-center"
                                style={{
                                  width: "100%",
                                  marginRight: "0px",
                                }}>
                                <div
                                  className="col-md-8 d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <img
                                    src={airlinrlogo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="ticktnam mx-2">
                                    <p className="ticktptag12">
                                      {ticket?.return_offer?.airline_name}
                                    </p>
                                    <p className="flight_class_name">
                                      {details?.[0]?.flight_class}
                                    </p>
                                    <p className="flight_class_name">
                                      {`${ticket?.return_offer?.airline_code}${ticket?.return_offer?.flight_number}`}

                                    </p>
                                    <p
                                      className="ticktptag21"
                                      style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                    >
                                      <span
                                        style={{
                                          color: "#61a108",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {ticket?.return_offer?.match_percentage}%
                                      </span>
                                      &nbsp;Matched
                                    </p>
                                    {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                      {ticket.return_offer?.percentage_changes}%
                                    </p> */}
                                  </div>


                                </div>
                                <div
                                  className="col-md-4 text-end"
                                >
                                  <p className="depature-heading">Return</p>
                                  <p className="depature_code d-flex justify-content-center"> {`${ticket?.return_offer && `$${ticket.return_offer?.price}`}`}</p>
                                  <p
                                    className="sndptag mb-0"
                                    style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                  >
                                    You Can Save{" "}
                                    <span
                                      style={{
                                        color: "#61a108",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {ticket?.return_offer?.saved_amount}

                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-6 Dtime`}>
                              <div className="Dtimelist">
                                <ul className="ul-list">
                                  <p className="ullist">
                                    {ticket?.return_offer?.departure_time?.split(" ")?.[0]}
                                    <span className="depature_time">
                                      {`${ticket?.return_offer?.departure_time?.split(" ")?.[1]} ${ticket?.return_offer?.departure_time?.split(" ")?.[2]}`}
                                    </span>
                                  </p>
                                  <p className="depature_code">
                                    {ticket.return_offer?.departure_iata_code}
                                  </p>
                                </ul>
                              </div>
                              <div className="Dtimelist">
                                <ul style={{ padding: "0" }}>
                                  <img
                                    src={plane}
                                    alt="Custom Plane"
                                    width="68px"
                                    style={{ marginTop: "20px" }}
                                  />
                                  <li className="ullist">
                                    {getTime(
                                      ticket?.return_offer?.departure_time,
                                      ticket?.return_offer?.arrival_time
                                    )}
                                  </li>
                                </ul>
                              </div>
                              <div className="Dtimelist">
                                <ul className="ul-list">
                                  <p className="ullist">
                                    {ticket?.return_offer?.arrival_time?.split(" ")?.[0]}
                                    <span className="depature_time">
                                      {`${ticket?.return_offer?.arrival_time?.split(" ")?.[1]} ${ticket?.return_offer?.arrival_time?.split(" ")?.[2]}`}
                                    </span>
                                  </p>
                                  <p className="depature_code">
                                    {ticket.return_offer?.arrival_iata_code}
                                  </p>
                                </ul>
                              </div>

                            </div>
                          </div>
                          <div className="px-5">
                            <ul className="row mt-2">
                              {ticket?.return_offer?.percentage_changes?.split(",")?.map((value) => {
                                if (value !== "") {
                                  return (
                                    <li
                                      className="col-md-12 hotel_desc_list"
                                      key={index}
                                    >
                                      {value}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        </div>


                        <div className="col-md-3">
                          {passBooking == "pass_booking" ? (
                            <>
                              {" "}
                              <div className="col-md-2 d-flex justify-content-end  airlinetkt">
                                <div className="col-md-12 text-end">
                                  <div className="ticket_detail_butns">
                                    <MDButton
                                      className={`past_status_butn ${ticket?.status == "ACCEPT" && "accepted_butn"
                                        }
                                ${ticket?.status == "REJECT" && "rejected_butn"}
                                ${ticket?.status == "ELIMINATE" &&
                                        "eliminated_butn"
                                        }
                                ${ticket?.status == "PENDING" && "accept_butn"
                                        }`}
                                    >
                                      {ticket?.status == "ACCEPT" && "ACCEPTED"}
                                      {ticket?.status == "REJECT" && "REJECTED"}
                                      {ticket?.status == "ELIMINATE" && "ELIMINATED"}
                                      {ticket?.status == "PENDING" && "PENDING"}
                                    </MDButton>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {ticket?.outbound_offer?.status == "PENDING" ? (
                                <div className="text-center">
                                  <div>
                                    <p className="fstptag"> Total Price</p>
                                    <p className="fstptag"> {ticket?.total_price}</p>
                                    {/* <p
                                      className="sndptag"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      You Can Save{" "}
                                      <span
                                        style={{
                                          color: "#61a108",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {ticket?.outbound_offer?.saved_amount}

                                      </span>
                                    </p> */}
                                  </div>
                                  <div className="ticket_detail_butns flex-column">
                                    <MDButton
                                      className="accept_butn"
                                      onClick={() =>
                                        handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                      }
                                      disabled={
                                        ticketStatuses[ticket?.outbound_offer?.id] ||
                                        ticket?.outbound_offer?.status !== "PENDING"
                                      }
                                    >
                                      ACCEPT
                                    </MDButton>
                                    <MDButton
                                      className={`reject_butn my-1`}
                                      onClick={() =>
                                        handleStatus(ticket?.outbound_offer?.id, "REJECT")
                                      }
                                      disabled={
                                        ticketStatuses[ticket?.outbound_offer?.id] ||
                                        ticket?.outbound_offer?.status !== "PENDING"
                                      }
                                    >
                                      REJECT
                                    </MDButton>
                                    {/* <MDButton
                                      className="eliminate_butn"
                                      onClick={() =>
                                        handleStatus(
                                          ticket?.outbound_offer?.id,
                                          "ELIMINATE"
                                        )
                                      }
                                      disabled={
                                        ticketStatuses[ticket?.outbound_offer?.id] ||
                                        ticket?.outbound_offer?.status !== "PENDING"
                                      }
                                    >
                                      ELIMINATE
                                    </MDButton> */}
                                  </div>

                                </div>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-end  airlinetkt ">
                                    <div className="col-md-12 text-center">
                                      <div>
                                        <p className="fstptag"> Total Price</p>
                                        <p className="fstptag"> {ticket?.total_price}</p>
                                        <p
                                          className="sndptag"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          You Can Save{" "}
                                          <span
                                            style={{
                                              color: "#61a108",
                                              fontWeight: "bold",
                                              fontSize: "13px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {ticket?.outbound_offer?.saved_amount}

                                          </span>
                                        </p>
                                      </div>
                                      <div className="ticket_detail_butns flex-column"
                                      // style={{
                                      //   position: 'relative',
                                      //   right: '0',
                                      //   top: '67px'
                                      // }}
                                      >
                                        {
                                          ticket?.outbound_offer?.status == "ELIMINATE" ?
                                            <MDButton
                                              className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                "accepted_butn"
                                                }`}
                                              disabled={
                                                ticket?.outbound_offer?.status == "ACCEPT"
                                              }
                                              onClick={() =>
                                                handleStatus(
                                                  ticket?.outbound_offer?.id,
                                                  "REINSTATE"
                                                )
                                              }
                                            >
                                              {ticket?.outbound_offer?.status == "ACCEPT"
                                                ? "ACCEPTED"
                                                : "ACCEPT"}
                                            </MDButton>
                                            :
                                            <MDButton
                                              className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                "accepted_butn"
                                                }`}
                                              disabled={
                                                ticket?.outbound_offer?.status == "ACCEPT"
                                              }
                                              onClick={() =>
                                                handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                              }
                                            >
                                              {ticket?.outbound_offer?.status == "ACCEPT"
                                                ? "ACCEPTED"
                                                : "ACCEPT"}
                                            </MDButton>

                                        }
                                        <MDButton
                                          className={`my-1 ${ticket?.outbound_offer?.status == "REJECT"
                                            ? "rejected_butn"
                                            : "reject_butn"
                                            }`}
                                          disabled={
                                            ticket?.outbound_offer?.status == "REJECT"
                                          }
                                          onClick={() =>
                                            handleStatus(
                                              ticket?.outbound_offer?.id,
                                              "REJECT"
                                            )
                                          }
                                        >
                                          {ticket?.outbound_offer?.status == "REJECT"
                                            ? "REJECTED"
                                            : "REJECT"}
                                        </MDButton>

                                        {/* <MDButton
                                          className={` ${ticket?.outbound_offer?.status == "ELIMINATE"
                                            ? "eliminated_butn"
                                            : "eliminate_butn"
                                            }`}
                                          disabled={
                                            ticket?.outbound_offer?.status == "ELIMINATE"
                                          }
                                          onClick={() =>
                                            handleStatus(
                                              ticket?.outbound_offer?.id,
                                              "ELIMINATE"
                                            )
                                          }
                                        >
                                          {ticket?.outbound_offer?.status == "ELIMINATE"
                                            ? "ELIMINATED"
                                            : "ELIMINATE"}
                                        </MDButton> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                    </>
                    :
                    <>
                      <div
                        className="row align-items-center"
                        style={{ alignItems: "center" }}
                      >
                        <div className={`col-md-4
                              d-flex airlinetkt align-items-center`}
                        >
                          <div
                            className="col-md-8 d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <img
                              src={airlinrlogo}
                              alt=""
                              className="img-fluid"
                            />
                            <div className="ticktnam mx-2">
                              <p className="ticktptag12">
                                {ticket?.outbound_offer?.airline_name}
                              </p>
                              <p className="flight_class_name">
                                {details?.[0]?.flight_class}
                              </p>
                              <p className="flight_class_name">
                                {`${ticket?.outbound_offer?.airline_code}${ticket?.outbound_offer?.flight_number}`}
                              </p>
                              <p
                                className="ticktptag21"
                                style={{ fontSize: "13px", marginBottom: "0px !important" }}
                              >
                                <span
                                  style={{
                                    color: "#61a108",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {ticket.outbound_offer.match_percentage}%
                                </span>
                                &nbsp;Matched
                              </p>
                              {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                {ticket.outbound_offer.percentage_changes}%
                              </p> */}
                            </div>


                          </div>
                          <div
                            className="col-md-4"
                          >
                            <p className="depature_code d-flex justify-content-center">    ${ticket.outbound_offer?.price}</p>

                          </div>

                        </div>

                        <div className={`col-md-4 Dtime`}>

                          <div className="Dtimelist">

                            <ul className="ul-list">


                              <p className="ullist">
                                {
                                  ticket?.outbound_offer?.departure_time?.split(
                                    " "
                                  )?.[0]
                                }
                                <span className="depature_time">
                                  {`${ticket?.outbound_offer?.departure_time?.split(
                                    " "
                                  )?.[1]
                                    } ${ticket?.outbound_offer?.departure_time?.split(
                                      " "
                                    )?.[2]
                                    }`}
                                </span>
                              </p>

                              <p className="depature_code">
                                {ticket.outbound_offer?.departure_iata_code}
                              </p>

                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul style={{ padding: "0" }}>
                              <img
                                src={plane}
                                alt="Custom Plane"
                                width="68px"
                                style={{ marginTop: "20px" }}
                              />
                              <li className="ullist">
                                {getTime(
                                  ticket?.outbound_offer?.departure_time,
                                  ticket?.outbound_offer?.arrival_time
                                )}
                              </li>
                            </ul>
                          </div>
                          <div className="Dtimelist">
                            <ul className="ul-list">
                              <p className="ullist">
                                {
                                  ticket?.outbound_offer?.arrival_time?.split(
                                    " "
                                  )?.[0]
                                }
                                <span className="depature_time">
                                  {`${ticket?.outbound_offer?.arrival_time?.split(
                                    " "
                                  )?.[1]
                                    } ${ticket?.outbound_offer?.arrival_time?.split(
                                      " "
                                    )?.[2]
                                    }`}
                                </span>
                              </p>

                              <p className="depature_code">
                                {ticket.outbound_offer?.arrival_iata_code}
                              </p>

                            </ul>
                          </div>
                        </div>


                        {ticket?.outbound_offer?.status == "PENDING" ? (
                          <div className="col-md-4 d-flex airlinetkt">
                            <div className="col-md-12">
                              <div className="ticket_detail_butns">
                                <MDButton
                                  className="accept_butn"
                                  onClick={() =>
                                    handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                  }
                                  disabled={
                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                    ticket?.outbound_offer?.status !== "PENDING"
                                  }
                                >
                                  ACCEPT
                                </MDButton>
                                <MDButton
                                  className={`reject_butn
                                    `}
                                  onClick={() =>
                                    handleStatus(ticket?.outbound_offer?.id, "REJECT")
                                  }
                                  disabled={
                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                    ticket?.outbound_offer?.status !== "PENDING"
                                  }
                                >
                                  REJECT
                                </MDButton>
                                <MDButton
                                  className="eliminate_butn"
                                  onClick={() =>
                                    handleStatus(
                                      ticket?.outbound_offer?.id,
                                      "ELIMINATE"
                                    )
                                  }
                                  disabled={
                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                    ticket?.outbound_offer?.status !== "PENDING"
                                  }
                                >
                                  ELIMINATE
                                </MDButton>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-md-4 d-flex justify-content-end  airlinetkt ">
                              <div className="col-md-12 text-end">
                                <div className="ticket_detail_butns"
                                // style={{
                                //   position: 'relative',
                                //   right: '0',
                                //   top: '67px'
                                // }}
                                >
                                  <MDButton
                                    className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                      "accepted_butn"
                                      }`}
                                    disabled={
                                      ticket?.outbound_offer?.status == "ACCEPT"
                                    }
                                    onClick={() =>
                                      handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                    }
                                  >
                                    {ticket?.outbound_offer?.status == "ACCEPT"
                                      ? "ACCEPTED"
                                      : "ACCEPT"}
                                  </MDButton>
                                  <MDButton
                                    className={`my-1 ${ticket?.outbound_offer?.status == "REJECT"
                                      ? "rejected_butn"
                                      : "reject_butn"
                                      }`}
                                    disabled={
                                      ticket?.outbound_offer?.status == "REJECT"
                                    }
                                    onClick={() =>
                                      handleStatus(
                                        ticket?.outbound_offer?.id,
                                        "REJECT"
                                      )
                                    }
                                  >
                                    {ticket?.outbound_offer?.status == "REJECT"
                                      ? "REJECTED"
                                      : "REJECT"}
                                  </MDButton>

                                  <MDButton
                                    className={`${ticket?.outbound_offer?.status == "ELIMINATE"
                                      ? "eliminated_butn"
                                      : "eliminate_butn"
                                      }`}
                                    disabled={
                                      ticket?.outbound_offer?.status == "ELIMINATE"
                                    }
                                    onClick={() =>
                                      handleStatus(
                                        ticket?.outbound_offer?.id,
                                        "ELIMINATE"
                                      )
                                    }
                                  >
                                    {ticket?.outbound_offer?.status == "ELIMINATE"
                                      ? "ELIMINATED"
                                      : "ELIMINATE"}
                                  </MDButton>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="px-5">
                        <ul className="row mt-2">
                          {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                            // if (value !== "") {
                            return (
                              <li
                                className="col-md-12 hotel_desc_list"
                                key={index}
                              >
                                {value}
                              </li>
                            );
                            // }
                          })}
                        </ul>
                      </div>
                    </>
                }


                {/* Percentage Changes */}
                <div>
                  <ul className="row mt-2">
                    {ticket.percentage_changes
                      ?.split(",")
                      .map((value, idx) => {
                        if (value !== "") {
                          return (
                            <li
                              className="col-md-12 hotel_desc_list"
                              key={idx}
                            >
                              {value}
                            </li>
                          );
                        }
                        return null;
                      })}
                  </ul>
                </div>
              </div>
            ))}

        </>
      )}

      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>

            {status == "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status == "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Reject this offer.
              </MDTypography>
            }

            {status == "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }
            {
              status == "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <>

                <div className="my-3 text-left">
                  <FormControl fullWidth>
                    <InputLabel id="reason-select-label">
                      Select Reason
                    </InputLabel>
                    <Select
                      labelId="reason-select-label"
                      value={selectedReason}
                      onChange={handleChange}
                      label="Select Reason"
                      className="select_reason_input"
                    >
                      {REASON_CHOICES?.map((reason) => (
                        <MenuItem key={reason[0]} value={reason[0]}>
                          {reason[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedReason === "OTHER" && (
                    <TextField
                      fullWidth
                      label="Please specify the reason"
                      variant="outlined"
                      value={otherReasonText}
                      onChange={(e) => {
                        setOtherReasonText(e.target.value);
                        setSelectMesage("");
                      }}
                      className="other_reason_input mt-3"
                      multiline
                      rows={3}
                    />
                  )}
                  <div className="error_message my-1">{selectMessage}</div>
                </div></>

            )}
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status == "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status == "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => handleAcceptSite()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubstituteTicket;
