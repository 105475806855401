import MDBox from "components/MDBox";
import * as React from "react";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import CarImg from "assets/user-dash/carImage.png";
import Loader from "components/Loader";

const PastCarBooking = ({carData,loading}) => {
  return (
    <MDBox py={3}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {carData?.map((item, index) => (
            <Accordion
              className="mb-3"
              defaultExpanded={index === 0}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="airline_header row">
                  <div className="airline_data col-md-6">
                    <img src={CarImg} alt="airline logo" />
                    <div className="airline_title_section">
                      <MDTypography
                        variant="h6"
                        color="white"
                        className="airline_heading"
                      >
                        {item?.car_model}
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        color="white"
                        className="ticket_title"
                      >
                        {`Pick up: ${item?.pickup_date}, ${
                          item?.pickup_location?.length > 20
                            ? `${item?.pickup_location?.slice(0, 20)}...`
                            : item?.pickup_location
                        }`}
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        color="white"
                        className="ticket_title"
                      >
                        Drop: {item?.dropoff_date}, {item?.dropoff_location}
                      </MDTypography>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <MDTypography
                      variant="h5"
                      color="white"
                      className="flight_details"
                    >
                      {item?.renter_name}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="ticket_title"
                    >
                      Booking reference: {item?.booking_reference}
                    </MDTypography>
                  </div>
                  <div className="col-md-3">
                    <MDTypography
                      variant="h5"
                      color="white"
                      className="flight_details"
                    >
                      {item?.rental_price}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="ticket_title"
                    >
                      {item?.rental_company}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="ticket_title"
                    >
                      {item?.rental_class}
                    </MDTypography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="substitues_section">
                {/* <Card className="hotel_cards"> */}
                  <div className="substitutes my-2 text-center">
                    <MDTypography variant="h6" color="white">
                      Substitutes Tickets
                    </MDTypography>
                  </div>

                  {/* <div className="airline_header substitutes_card_flight">
                    <div className="ticket_detail_heading">
                      <MDTypography variant="h6">
                        Orlando-to-Los Angeles
                      </MDTypography>
                      <div className="ticket_details">
                        <MDTypography variant="p" className="ticket_title">
                          28 Sep
                        </MDTypography>
                        <span>|</span>
                        <MDTypography variant="p" className="ticket_title">
                          1 Adult
                        </MDTypography>
                      </div>
                    </div>

                    <div className="flight_price">
                      <MDTypography variant="p" className="ticket_title">
                        76% Matched with your Current Ticket
                      </MDTypography>
                    </div>
                    <div className="ticket_detail_butns">
                      <MDButton className="accept_butn">ACCEPT</MDButton>
                      <MDButton className="reject_butn">REJECT</MDButton>
                      <MDButton className="eliminate_butn">Eliminate</MDButton>
                    </div>
                  </div> */}
                {/* </Card> */}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </MDBox>
  );
};

export default PastCarBooking;
