import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState, useEffect, useRef } from "react";
import { getProfile, updateProfile, updateUserPassword } from "utils/api";
import defaultAvatar from "assets/images/defaultAvatar.jpg";
import "./profile.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDAlert from "components/MDAlert";
import Loader from "components/Loader";

const profile_tabs = [
  { label: "Edit Name", value: 1 },
  { label: "Change Profile Picture", value: 2 },
  { label: "Update Email Id", value: 3 },
  { label: "Change Password", value: 4 },
];
function Overview() {
  const [profile, setProfile] = useState(null);
  const [active_tab, setActiveTab] = useState(1);
  const [formdata, setFormdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    new_email: "",
    current_password: "",
    new_password: "",
    confirm_password: "",
    image: null,
  });
  const [errors, setErrors] = useState({});
  const [hoveredAction, setHoveredAction] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [current_eye, setCurrentEye] = useState(false);
  const [new_eye, setNewEye] = useState(false);
  const [confirm_eye, setConfirmEye] = useState(false);
  const inputRef = useRef(null);
  const [image_updated, setImageUpdated] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");

  useEffect(() => {
    getProfile()
      .then((res) => {
        if (res.status === 200) {
          setProfile(res.data);
          setFormdata({
            ...formdata,
            first_name: res.data.first_name || "",
            last_name: res.data.last_name || "",
            email: res.data.email || "",
            image: res.data.image || "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleFileChange = (e) => {
    setImageUpdated(e.target.files[0])
    setFormdata({ ...formdata, image: URL.createObjectURL(e.target.files[0]) });
  };

  const validateEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleUpdate = () => {
    if (active_tab == 4) {
      let data = {
        old_password: formdata.current_password,
        new_password: formdata.new_password,
        confirm_password: formdata.confirm_password,
      }
      setLoading(true);
      updateUserPassword(data).then((res) => {
        setLoading(false)
        if (res.status === 200) {
          setSuccessMessage("Password updated successfully.")
          setTimeout(() => {
            setSuccessMessage("")
          }, 3000)
        } else {
          setErrorAlert(res.data[Object.keys(res.data)][0])
          setTimeout(() => {
            setErrorAlert("")
          }, 3000)
        }
      })
    } else {
      const {
        first_name,
        last_name,
        email
      } = formdata;
      let validationErrors = {};

      if (!first_name) {
        validationErrors.first_name = "First name is required.";
      }
      if (!last_name) {
        validationErrors.last_name = "Last name is required.";
      }
      if (!validateEmail(email)) {
        validationErrors.email = "Invalid email address.";
      }
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      const updateData = new FormData();
      updateData.append("first_name", first_name);
      updateData.append("last_name", last_name);
      updateData.append("email", email);
      if (formdata?.image && formdata?.image !== profile?.image) {
        updateData.append("image", image_updated);
      }
      setLoading(true);
      updateProfile(updateData)
        .then((res) => {
          setLoading(false)
          if (res.status === 200) {
            setFormdata({
              ...formdata,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              image: res.data.image
            })
            setProfile(res.data);
            localStorage.setItem("active-user", JSON.stringify(res.data));
            setSuccessMessage("Profile updated successfully!");
            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);
          } else {
            console.error("Profile update failed.");
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log(error);
        });
    }
  };

  const buttonStyle = {
    backgroundColor: "#F5F6F8",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    width: "100%",
    textAlign: "left",
    borderRadius: "5px",
    transition: "all 0.3s ease",
    marginTop: "-20px",
  };

  const getTextStyle = (tab) => {
    if (active_tab === tab || hoveredAction === tab) {
      return {
        background:
          "linear-gradient(269.54deg, #02F4FB -35.77%, #3252E7 120.2%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        transition: "all 0.3s ease",
      };
    }
    return {
      color: "#404A74",
      transition: "all 0.3s ease",
    };
  };

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {successMessage}
        {errorAlert}
      </MDTypography>
    </MDTypography>
  );

  return (
    <>
      {
        loading && (
          <Loader />
        )
      }
      <div
        style={{
          position: "absolute",
          right: "25px",
          top: "20px",
          zIndex: "99999",
        }}
      >
        {successMessage && (
          <MDAlert color="info" dismissible>
            {alertContent("info")}
          </MDAlert>
        )}
        {errorAlert && (
          <MDAlert color="error" dismissible>
            {alertContent("error")}
          </MDAlert>
        )}
      </div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox className="tabs">
          <MDBox className="subtabs">
            {/* {profile && ( */}
              <>
                <div className="my-5 text-center">
                  <MDBox>
                    <div className="profile">
                      <img
                        src={profile?.image || defaultAvatar}
                        alt="Profile"
                        className="Profile-image"
                      />
                    </div>
                  </MDBox>
                  <div className="name my-3">
                    <MDTypography variant="h3" className="text-capitalize">
                      {profile?.first_name} {profile?.last_name}
                    </MDTypography>
                  </div>
                </div>
                <MDTypography variant="h5" className="profiletext">
                  Profile
                </MDTypography>
                <div className="mt-4 container profile-detail">
                  {profile_tabs?.map((tab) => (
                    <button
                      key={tab.value}
                      style={{
                        ...buttonStyle,
                        marginTop: tab.value !== 1 ? "-20px" : "0",
                      }}
                      onClick={() => { setActiveTab(tab.value); setImageUpdated(false) }}
                      onMouseEnter={() => setHoveredAction(tab.value)}
                      onMouseLeave={() => setHoveredAction(null)}
                    >
                      <span
                        className="userDetail"
                        style={getTextStyle(tab.value)}
                      >
                        {tab.label}
                      </span>
                      <ArrowForwardIosIcon className="arrowicon" />
                      {tab.value !== 4 && (
                        <hr
                          style={{
                            border: "1px solid #ccc",
                            marginTop: "10px",
                          }}
                        />
                      )}
                    </button>
                  ))}
                </div>
              </>
            {/* )} */}
          </MDBox>
          {active_tab && (
            <MDBox className="profile_form">
              <MDBox className="subform">
                <div className="label-container mb-3">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div className="arrowicon2">
                      <ArrowBackIcon
                        style={{
                          background:
                            "linear-gradient(269.54deg, #02F4FB -35.77%, #3252E7 120.2%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <MDTypography
                      variant="h5"
                      style={{ fontSize: "18px", alignSelf: "center" }}
                    >
                      {
                        profile_tabs?.find((tab) => tab?.value === active_tab)
                          ?.label
                      }
                    </MDTypography>
                  </div>
                </div>
                <form className="inner_form">
                  {active_tab === 1 && (
                    <div>
                      <div className="full-input">
                        <label className="profile_label" for="first_name">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          value={formdata?.first_name}
                          onChange={handleInputChange}
                          className="inputbox"
                          id="first_name"
                        />
                      </div>
                      {errors.first_name && <p>{errors.first_name}</p>}
                      <div className="full-input">
                        <label className="profile_label" for="last_name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          value={formdata.last_name}
                          onChange={handleInputChange}
                          className="inputbox"
                          id="last_name"
                        />
                      </div>
                      {errors.last_name && <p>{errors.last_name}</p>}
                    </div>
                  )}
                  {active_tab === 2 && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      marginBottom="16px"
                    >
                      <div className="profile-image-container mt-3">
                        <img
                          src={formdata?.image || defaultAvatar}
                          alt="Profile"
                          className="rounded-circle profile-image"
                        />
                        <div className="overlay-background"></div>
                      </div>
                      <div className="input-container">
                        <input
                          type="file"
                          id="upload"
                          hidden
                          accept="image/*"
                          onChange={handleFileChange}
                          ref={inputRef}
                        />
                        <button
                          className="image_upload_label"
                          for="upload"
                          type="button"
                          onClick={() => { inputRef.current.click() }}
                        >
                          CHOOSE ANOTHER
                        </button>
                      </div>
                    </MDBox>
                  )}
                  {active_tab === 3 && (
                    <div>
                      <div className="full-input">
                        <label className="profile_label" for="email">
                          Current Email Id
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={formdata.email}
                          disabled
                          className="inputbox"
                          id="email"
                        />
                      </div>
                      {errors.email && <p>{errors.email}</p>}
                      {/* <div className="full-input">
                          <label className="profile_label" for="new_email">New Email Id</label>
                          <input type="text" name="new_email" value={formdata.new_email} onChange={handleInputChange} className="inputbox" id="new_email" />
                        </div>
                        {errors.new_email && <p>{errors.new_email}</p>} */}
                    </div>
                  )}
                  {active_tab === 4 && (
                    <div>
                      <div className="full-input">
                        <label className="profile_label" for="current_password">
                          Current Password
                        </label>
                        <input
                          type={!current_eye && "password"}
                          name="current_password"
                          value={formdata.current_password}
                          onChange={handleInputChange}
                          className="inputbox"
                          id="current_password"
                        />
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => setCurrentEye(!current_eye)}
                          style={{ marginLeft: "-40px", marginTop: "-20px" }}
                        >
                          {!current_eye ? (
                            <VisibilityOff
                              sx={{ color: "rgba(64, 74, 116, 1)" }}
                            />
                          ) : (
                            <Visibility sx={{ color: "rgba(64, 74, 116, 1)" }} />
                          )}
                        </IconButton>
                      </div>
                      {errors.current_password && (
                        <p>{errors.current_password}</p>
                      )}
                      <div className="full-input">
                        <label className="profile_label" for="new_password">
                          New Password
                        </label>
                        <input
                          type={!new_eye && "password"}
                          name="new_password"
                          value={formdata.new_password}
                          onChange={handleInputChange}
                          className="inputbox"
                          id="new_password"
                        />
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => setNewEye(!new_eye)}
                          style={{ marginLeft: "-40px", marginTop: "-20px" }}
                        >
                          {!new_eye ? (
                            <VisibilityOff
                              sx={{ color: "rgba(64, 74, 116, 1)" }}
                            />
                          ) : (
                            <Visibility sx={{ color: "rgba(64, 74, 116, 1)" }} />
                          )}
                        </IconButton>
                      </div>
                      {errors.confirm_password && (
                        <p>{errors.confirm_password}</p>
                      )}
                      <div className="full-input">
                        <label className="profile_label" for="confirm_password">
                          Confirm Password
                        </label>
                        <input
                          type={!confirm_eye && "password"}
                          name="confirm_password"
                          value={formdata.confirm_password}
                          onChange={handleInputChange}
                          className="inputbox"
                          id="confirm_password"
                        />
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => setConfirmEye(!confirm_eye)}
                          style={{ marginLeft: "-40px", marginTop: "-20px" }}
                        >
                          {!confirm_eye ? (
                            <VisibilityOff
                              sx={{ color: "rgba(64, 74, 116, 1)" }}
                            />
                          ) : (
                            <Visibility sx={{ color: "rgba(64, 74, 116, 1)" }} />
                          )}
                        </IconButton>
                      </div>
                      {errors.confirm_password && (
                        <p>{errors.confirm_password}</p>
                      )}
                    </div>
                  )}
                  {active_tab !== 3 && (
                    <div className="button-section">
                      <MDButton className="updateButton" onClick={handleUpdate}>
                        Update
                      </MDButton>
                    </div>
                  )}
                </form>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Overview;
